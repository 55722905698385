import { Below } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import Carousel from 'my-react-carousel';
import React from 'react';
import { ReactComponent as IconArrow } from '../../svg/IconArrow.svg';
import { ProductCard } from '../CategoryPage/ProductCard';

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const ItemContainer = styled('div')`
  .slider-product {
    width: 100%;
  }
`;

const Arrow = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 100%;
  transform: translateY(-40%);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 2px;
  border: 0;
  top: 30%;
  transform: translate(-30%);

  &[data-direction='prev'] {
    left: -12px;
    transform: rotate(180deg);
  }
  &[data-direction='next'] {
    right: -23px;
  }
`;

function CarouselComponent({ children }) {
  return (
    <Below breakpoint="md">
      {matches => (matches ? children(2) : children(4))}
    </Below>
  );
}

const CarouselMain = ({ children }) => {
  const carousel = React.useRef(null);

  return (
    <CarouselComponent>
      {slides => (
        <Container>
          <Carousel
            ref={carousel}
            slidesToShow={slides}
            infinite={true}
            centerCurrentSlide={false}
          >
            {React.Children.toArray(children).map(({ props }, index) => {
              const { product } = props;
              return (
                <ItemContainer key={index + ':' + product.articleNumber}>
                  <ProductCard
                    product={product}
                    className="slider-product"
                    slider={true}
                    as="div"
                  />
                </ItemContainer>
              );
            })}
          </Carousel>
          <Arrow
            onClick={() => carousel.current.previous()}
            data-direction="prev"
          >
            <IconArrow />
          </Arrow>
          <Arrow onClick={() => carousel.current.next()} data-direction="next">
            <IconArrow />
          </Arrow>
        </Container>
      )}
    </CarouselComponent>
  );
};

export default CarouselMain;
