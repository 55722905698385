import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Search } from '../../../svg/IconSearch.svg';
import { theme } from '../../Theme';

const Button = styled.button`
  position: absolute;
  top: 2px;
  left: -34px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: transparent;

  &[data-search-open='true'] {
    position: absolute;
    left: 0;
    z-index: 999;
  }

  svg {
    width: 20px;
    height: 20px;
    stroke: #000;

    &:hover {
      stroke: ${theme.colors.primaryHover};
    }
  }
`;

function SearchButton(props) {
  const { setSearchOpen, searchOpen, ...rest } = props;

  return (
    <Button
      onClick={() => setSearchOpen(state => !state)}
      data-search-open={searchOpen}
      {...rest}
    >
      <Search />
    </Button>
  );
}

export default SearchButton;
