import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';

import { theme } from '../../../Theme';

const Wrapper = styled('div')`
    position: relative;

    > ul {
        position: relative;
        margin: 0;
        padding: 12px 0 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        z-index: 14;
        color: ${theme.colors.black};


    }

    &[data-scrolled='true'] > ul {
        margin-left: 2rem;

        ${theme.below.xl} {
            
            > li > span, 
            > li > a {
                font-size: 1.05vw;
                padding-left: 0.9vw;
                padding-right: 0.9vw;
            }
        }
        
    }
    
    .active-sub a,
    .active-sub span {
        background: ${theme.colors.beige};
    }
`;

const MainMenuItem = styled('li')`
    a, span {
        position: relative;
        padding: 1.4rem 1rem 1.4rem;
        display: inline-block;
        color: ${theme.colors.black};
        text-decoration: none;
        font-size: 0.85rem;
        font-weight: ${theme.fontWeights.medium};

        &:hover {
            text-decoration: none;
        }

        ${theme.below.xl} {
            font-size: 0.75rem;
        }


    }

    &:not(.active-sub) a,
    &:not(.active-sub) span {
        &.active,
        &:hover {
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 20px;
                left: 16px;
                width: calc(100% - 32px);
                height: 1px;
                background-color: ${theme.colors.black};
            }
        }
    }
`;

let catRef = null;

const MenuContainer = ({ scrolled, queryData, isOpen = undefined }) => {
  const [hoverMenu, setHoverMenu] = React.useState(false);
  const [activeCat, setActiveCat] = React.useState('');

  const handleMouseEnter = (cat, setActiveCategory) => {
    if (activeCat === '') {
      setActiveCategory(cat);
      if (cat.subcategories.length > 0) {
        setHoverMenu(true);
      }
    } else {
      const ref = setTimeout(() => {
        setActiveCategory(cat);
        if (cat.subcategories.length > 0) {
          setHoverMenu(true);
        } else {
          setHoverMenu(false);
        }
      }, 240);
      catRef = ref;
    }
    setActiveCat(cat);
  };

  const handleMouseLeave = () => {
    clearTimeout(catRef);
  };

  return (
    <UIMenuContainer>
      {props => {
        return (
          <UIMenuContainerInner {...props} isOpen={isOpen}>
            {innerProps => (
              <Wrapper data-scrolled={scrolled}>
                <ul data-hover={hoverMenu}>
                  {queryData && queryData.categories
                    ? queryData.categories
                    .filter(item => item.level === 1)
                    .map(cat => {
                      if (
                        innerProps.excludeCategories.indexOf(cat.id) !== -1
                      ) {
                        return null;
                      }

                      const catName = cat.name.toLowerCase();
                      return (
                        <MainMenuItem
                          key={cat.id}
                          // When mousing over a menu item, set it as the active nav
                          onMouseEnter={() => {
                            handleMouseEnter(
                              cat,
                              props.setActiveCategory
                            );
                          }}
                          scrolled={scrolled}
                          linkName={cat.name}
                          className={
                            innerProps.activeCat === catName &&
                            props.activeCategories[0].subcategories
                              .length > 0
                              ? 'active-sub'
                              : ''
                          }
                          onMouseLeave={() => {
                            handleMouseLeave();
                          }}
                        >
                          {cat.id === 162 ? (
                            // Temp fix for disabling clicks on Varumärken
                            <span>{cat.name}</span>
                          ) : (
                            <CategoryLink
                              // When following a category link, close the menu
                              onClick={props.clearActiveCategories}
                              category={cat}
                            >
                              {cat.name}
                            </CategoryLink>
                          )}
                        </MainMenuItem>
                      );
                    })
                    : null}
                </ul>

                <SubMenuWrapper
                  clearActiveCategories={props.clearActiveCategories}
                  setHoverMenu={setHoverMenu}
                  pose={
                    props.activeCategories.length > 0 &&
                    props.activeCategories[0].subcategories.length > 0
                      ? 'open'
                      : 'closed'
                  }
                  activeCategory={props.activeCategories[0]}
                  activeCategories={props.activeCategories}
                  closeNav={props.clearActiveCategories}
                  scrolled={scrolled}
                />
              </Wrapper>
            )}
          </UIMenuContainerInner>
        );
      }}
    </UIMenuContainer>
  );
};

const UIMenuContainerInner = (props, { isOpen }) => {
  const menuOpen =
    props.activeCategories.length > 0 &&
    props.activeCategories[0].subcategories.length > 0
      ? true
      : false;

  isOpen && isOpen(menuOpen);

  const excludeCategories = [194, 195, 196];
  const activeCat =
    props.activeCategories[0] && props.activeCategories[0].name.toLowerCase();

  return props.children({ excludeCategories, activeCat });
};

export default MenuContainer;
