import React from 'react';
import UIButton from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';

export const ButtonStyleBasic = `
  padding: 1em 2.825em;
  background: ${theme.colors.primary};
  border-color: ${theme.colors.primary};
  text-align: center;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.primary};
  font-size: 0.875em;
  font-weight: ${theme.fontWeights.medium};
  text-transform: uppercase;
  letter-spacing: 1px;
  transition-property: background-color, border-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  outline: none;

  p,
  span {
    font-weight: ${theme.fontWeights.medium};
  }

  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }

  ${theme.above.md} {
    max-width: 100%;
  }

  :disabled {
    background: ${theme.colors.grey};
    border: 1px solid #dedede;
    color: #808080;
    opacity: 0.7;
  }

  &:hover {
    background-color: ${theme.colors.primaryHover};
    border-color: ${theme.colors.primaryHover};
  }
`;

const Button = styled(UIButton)`
  ${ButtonStyleBasic};
  display: inline-block;
  color: ${theme.colors.white};
`;

// export const StyledTrendLink = styled(Link)`
//   ${ButtonStyleBasic};
//   display: inline-block;
//   background-color: white;
//   color: ${theme.colors.primary};

//   &:hover {
//     background-color: rgba(255, 255, 255, 0.9);
//   }
// `;

const TrendABase = styled('a')`
  ${ButtonStyleBasic};
  display: inline-block;
  background-color: white;
  color: ${theme.colors.primary};

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const TrendLinkBase = styled(Link)`
  ${ButtonStyleBasic};
  display: inline-block;
  background-color: white;
  color: ${theme.colors.primary};

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

// export const TrendLink = props => {
//   return <StyledTrendLink {...props} />;
// };

function isRelativeUrl(url) {
  return url && !url.startsWith('http://') && !url.startsWith('https://');
}

export function TrendLink({ to, ...props }) {
  return typeof to === 'string' && !isRelativeUrl(to) ? (
    <TrendABase href={to} {...props} />
  ) : (
    <TrendLinkBase to={to} {...props} />
  );
}

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) =>
  loading ? (
    <Button {...props}>
      {loadingText}
      <StyledSpinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
