module.exports = callback => {
  let busy = false;

  return (...args) => {
    if (busy) return;

    busy = true;
    callback.apply(this, args);

    window.requestAnimationFrame(() => {
      busy = false;
    });
  };
};
