let colors = {
  beige: '#D7D2CB',
  black: '#000000',
  blue: '#40689A',
  blueMediumDark: '#23446E',
  blueDark: '#122945',
  darkgrey: '#333',
  mediumgrey: '#75787B',
  grey: '#D7D2CB',
  greyLight: '#F7F7F7',
  green: '#39AD6A',
  greenLight: '#4FCB83',
  pink: '#F4DDDD',
  red: '#E52D58',
  redDark: '#811140',
  tablegrey: '#F3F3F3',
  yellow: '#FFDA5D',
  white: '#FFFFFF'
};

const themeColors = {
  primary: colors.blueDark,
  primaryHover: colors.blueMediumDark,
  background: colors.greyLight,
  highlight: colors.blueDark,
  loadingBar: colors.blueDark,
  blueDarkHover: colors.blueMediumDark,
  greenHover: colors.greenLight
};

const fonts = {
  primary: 'HCo Gotham SSm',
  secondary: 'AlFresco'
};

module.exports = {
  default: {
    colors: {
      ...colors,
      ...themeColors
    },
    fontWeights: {
      light: 300,
      book: 300,
      regular: 500,
      medium: 500,
      semibold: 700
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      lgl: '78.125rem',
      xl: '80rem'
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts: {
      primary: 'HCo Gotham SSm',
      secondary: 'AlFresco'
    },
    mixins: {
      smallHeading: `
        font-size: .75rem;
        line-height: 1.375rem;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 1px;
      `,
      mediumHeading: `
        font-size: 2.25rem;
        font-weight: 500;
        @media (max-width: 39.99rem) {
          font-size: 1.625rem;
          line-height: 2rem;
        }
      `,
      overlay: `
        position: relative;
    
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, .15);
          transition: background ease-in-out .2s;
        }
    
        &:hover {
          &:after {
            background: rgba(0, 0, 0, .05);
          }
        }`
    },
    buttonStyle: `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.625rem;
    background: ${themeColors.primary};
    border-color: ${themeColors.primary};
    color: white;
    font-family: ${fonts.primary};
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    outline: none;
  
    p, span {
      font-weight: 500;
    }
  
    svg {
      fill: ${colors.white};
      stroke: ${colors.white};
      height: 1.2em;
      width: 1.2em;
      margin-left: 1em;
    }
    @media screen and (max-width: 50rem) {
      max-width: 100%;
    }
  
    :disabled {
      background: ${colors.grey};
      border: 1px solid #dedede;
      color: #808080;
      opacity: 0.7;
    }
  
    &:hover {
      background-color: ${themeColors.primaryHover};
      border-color: ${themeColors.primaryHover};
    }`
  }
};
