import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as LogoTEH } from '../../../svg/teh-logotype.svg';
import { Helmet } from 'react-helmet-async';

const TEHWrapper = styled('div')`
  width: 70px;
  height: 70px;
  display: block;
  padding: 10px 0;
`;

export const TryggHandelScript = () => {
  return (
    <Helmet>
      <script src="https://cert.tryggehandel.net/js/script.js?id=3a48e7fa-e106-417f-9c0a-fc43a3f36a52" defer async></script>
    </Helmet>
  );
};

export const TryggHandelLogo = () => {
  return (
    <TEHWrapper>
      <div className="teh-certificate" data-size="70">
        <LogoTEH />
      </div>
    </TEHWrapper>
  )
};

export default TryggHandelLogo;
