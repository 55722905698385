import { useEffect } from 'react';

const Livechat = () => {
  useEffect(() => {
    if (typeof (window !== 'undefined')) {
      const chatElement = document.getElementById('purechat-container');
      if (!chatElement) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.setAttribute('data-cfasync', 'false');

        s.innerHTML = `window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } }; (function () { var done = false; var script = document.createElement('script'); script.async = true; script.type = 'text/javascript'; script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; document.getElementsByTagName('HEAD').item(0).appendChild(script); script.onreadystatechange = script.onload = function (e) { if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { var w = new PCWidget({c: '7fc22f9b-cf3d-4587-b680-55ab3bf82a75', f: true }); done = true; } }; })();`;

        document.body.appendChild(s);
      }
    }
  }, []);

  return null;
};

export default Livechat;
