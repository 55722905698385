import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import MobileCategories from './Categories/MobileCategories';
// import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
// import CloseButton from '../../ui/CloseButton';
import { Link } from 'react-router-dom';
// import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import SearchField from '../../Theme/JetshopUi/SearchField';
import { ReactComponent as Search } from '../../../svg/IconSearch.svg';
import { ReactComponent as IconStoreFind } from '../../../svg/IconStoreFind.svg';
import AutocompleteQuery from './AutocompleteQuery.gql';
// import { clear } from 'sisteransi';
import { useIntl } from '@jetshop/intl';

import { theme } from '../../Theme';

const StyledIconStoreFinder = styled(IconStoreFind)`
  width: 22px;
  height: 22x;
  margin-right: ${theme.space[2]};
  stroke: ${theme.colors.primary};
`;

// const StyledCloseButton = styled(CloseButton)`
//   margin-left: 0;
//   margin-right: auto;
// `;

// const Title = styled('h1')`
//   text-align: center;
//   position: absolute;
// `;

const extraBackgroundCSS = css`
  position: relative;
  top: 0;
  height: 64px;
  background-color: ${theme.colors.primary};
  z-index: -10;
`;

const NavBarHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 64px;
  background-color: ${theme.colors.primary};
  z-index: -1;

  &.is-sub-open {
    display: none !important;
  }

  .search-wrapper {
    width: 100%;
  }

  .searchfield-wrapper {
    display: flex;
    height: 42px;
    width: calc(100% - 32px);
    margin: auto;

    input {
      border: 0;
      height: 100%;
      padding: 0 0 0 36px;
      width: 100%;
      border-bottom: 1px solid ${theme.colors.white};
      outline: none;
      font-size: 1.2rem;
      color: ${theme.colors.white};
      background: none;
      border-radius: 0;
      -webkit-appearance: none;
    }
  }

  .searchresult-wrapper {
    position: absolute;
    top: 64px;
    bottom: 0;
    right: 0;
    width: 320px;
    padding: 0;
    background: ${theme.colors.white};
    border-top: none;
    z-index: 10;

    &.open {
      position: fixed;
    }

    ul {
      height: calc(100% - 60px);
      overflow: auto;
    }

    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0;
    }
    a {
      padding: 1rem;
      font-size: 1.2rem;
      display: block;
    }
  }
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  background: ${theme.colors.white};
`;

const SecondaryMenu = styled.ul`
  margin: 0;
  padding: 1rem;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  flex: 0 0 auto;
  /* height: 100%; */
  background: ${theme.colors.white};
  border-bottom: 1px solid #e8e8e8;
`;

const SecondaryMenuItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;

  a,
  a:active {
    display: block;
    text-decoration: none;
    padding: 0.5rem 0;
    font-size: 1rem;
  }
`;

const StyledSearch = styled(Search)`
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  stroke: ${theme.colors.white};
  cursor: pointer;
`;

const Cover = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
`;

const DrawerWrapper = styled('div')`
  > div {
    max-width: calc(100% - 32px);
    overflow: hidden;
    background: ${theme.colors.primary};
  }
`;

export default function MobileMenu({ data }) {
  const searchSection = React.createRef();
  const clearInputRef = React.createRef();
  const t = useIntl();

  const handleKeyPress = drawer => {
    if (typeof window !== 'undefined') {
      const keyPress = new KeyboardEvent('keydown', {
        bubbles: true,
        cancelable: true,
        keyCode: 13
      });

      searchSection.current.querySelector('input').dispatchEvent(keyPress);
      drawer.hideTarget();

      setTimeout(() => {
        clearInputRef.current && clearInputRef.current();
      }, 200);
    }
  };

  const [searchResultOpen, setSearchResultOpen] = React.useState(false);
  const [isSubOpen, setIsSubOpen] = React.useState(false);

  const searchResult = isOpen => {
    setSearchResultOpen(isOpen);
  };

  if (!(data && data.categories)) return null;

  return (
    <DrawerTarget id="menu-drawer">
      {drawer => {
        return (
          <DrawerWrapper>
            <Drawer right={true} isOpen={drawer.isOpen}>
              <NavBarHeader
                className={isSubOpen ? 'is-sub-open' : null}
                searchResultOpen={searchResultOpen}
                isSubOpen={isSubOpen}
              >
                {!isSubOpen && (
                  <div style={{ width: '100%' }} ref={searchSection}>
                    <StyledSearch
                      onClick={() => {
                        handleKeyPress(drawer);
                      }}
                    />

                    <SearchField
                      autocompleteQuery={AutocompleteQuery}
                      placeholder={t('Sök här')}
                      searchResult={searchResult}
                      resultClick={drawer.hideTarget}
                      clearInputRef={clearInputRef}
                      handleKeyPress={handleKeyPress}
                      drawer={drawer}
                      onCancel={() => false}
                    />
                  </div>
                )}
              </NavBarHeader>

              <Scroll>
                {isSubOpen && <div className={extraBackgroundCSS} />}
                <MobileCategories
                  categories={data.categories}
                  closeMenu={drawer.hideTarget}
                  isOpen={drawer.isOpen}
                  setIsSubOpen={setIsSubOpen}
                />
                <SecondaryMenu>
                  <SecondaryMenuItem>
                    <StyledIconStoreFinder />

                    <Link to="/butiker" onClick={drawer.hideTarget}>
                      {t('Stores')}
                    </Link>
                  </SecondaryMenuItem>
                </SecondaryMenu>
              </Scroll>
              {searchResultOpen && (
                <Cover
                  onClick={() => {
                    handleKeyPress(drawer);
                  }}
                />
              )}
            </Drawer>
          </DrawerWrapper>
        );
      }}
    </DrawerTarget>
  );
}
