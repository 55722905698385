import React from 'react';
import qs from 'qs';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { withRouter } from 'react-router-dom';
import t from '@jetshop/intl';
import MaxWidth from '../Layout/MaxWidth';
import ProductGrid from '../CategoryPage/ProductGrid';

import useFavourites, {
  findMatchingArticleNumber as findMatchingArticleNumberInQuery
} from './useFavourites';
import { CenteredSpinner } from '../ui/Spinner';
import AddManyToCartButton from '../ProductPage/AddToCart/AddManyToCartButton';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';

import ProductsWithVariantsQuery from './ProductsWithVariantsQuery.gql';
import { useQuery } from 'react-apollo';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { ReactComponent as IconCopy } from '../../svg/IconCopy.svg';

const StyledIconCopy = styled(IconCopy)`
  width: 18px;
  height: 24px;
  stroke: ${theme.colors.white};
  margin-left: 1rem;
`;

// const StyledSizeAndStockWrapper = styled.div`
//   margin-top: 0.5rem;
//   ${theme.above.md} {
//     padding-right: 0px;
//   }
// `;

// const Row = styled('div')`
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   width: 100%;
//   margin: 0.5rem 0;

//   &::after {
//     content: ' ';
//     display: block;
//     flex: auto;
//   }
// `;

const ClearFavouritesButton = styled('button')`
  ${theme.buttonStyle};
  min-width: 320px;
  margin: ${theme.space[3]} auto 0;
`;

const NoFavouritesInfo = styled('p')`
  text-align: center;
`;

const FavouritesLinkSection = styled('section')`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  max-width: 90%;
  margin: 0 auto ${theme.space[4]};
  cursor: pointer;
`;

const PageDescription = styled('p')`
  text-align: center;
  padding: 0 0.5rem 1.5rem;
  max-width: 700px;
  margin: 0 auto;
`;

const FavouritesLink = styled('input')`
  width: 100%;
  border: 1px solid ${theme.colors.grey};
  color: ${theme.colors.primary};
  padding: ${theme.space[2]} 0 ${theme.space[2]} ${theme.space[2]};
  text-decoration: underline;
  font-size: 1rem;
  font-weight: ${theme.fontWeights.medium};
  outline: none;

  cursor: pointer;
  background: ${theme.colors.greyLight};
`;

const CopiedButton = styled('span')`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  padding: 0 ${theme.space[3]};
  color: ${theme.colors.white};
  white-space: nowrap;
  font-size: 0.85rem;
  text-transform: uppercase;
  background: ${theme.colors.primary};

  &[data-copied='true'] {
    background: ${theme.colors.green};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 1;
    left: -84px;
    width: 84px;
    height: 50px;
    background: rgb(247, 247, 247);
    background: linear-gradient(
      90deg,
      rgba(247, 247, 247, 0) 0%,
      rgba(247, 247, 247, 1) 80%
    );
  }
`;

const PageHeader = styled('h1')`
  text-align: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`;

const getUrlPath = () => window.location.origin + window.location.pathname;

function useShareFavourites(location) {
  const {
    favourites: localFavourites,
    clearFavourites,
    findMatchingArticleNumber
  } = useFavourites();

  const { shared, ...restParams } =
    qs.parse(location.search, { ignoreQueryPrefix: true }) || {};

  const qsFavourites = shared ? shared.split(',') : [];
  const isSharedFavourites = qsFavourites.length > 0;

  const articleNumbers = isSharedFavourites
    ? qsFavourites
    : localFavourites.map(f => f.articleNumber);

  const { data, loading } = useQuery(ProductsWithVariantsQuery, {
    variables: { articleNumbers },
    errorPolicy: 'ignore'
  });

  const favourites =
    data && data.products
      ? data.products.map(product => {
          const articleNumber =
            findMatchingArticleNumber(product) ||
            findMatchingArticleNumberInQuery(qsFavourites, product);
          const selectedVariant =
            product.variants &&
            product.variants.values.find(
              variant => variant.articleNumber === articleNumber
            );
          return {
            product,
            selectedVariant
          };
        })
      : [];

  const shouldSelectVariants = favourites.some(
    favourite =>
      favourite.product.variants &&
      favourite.product.variants.values &&
      favourite.product.variants.values.length > 0 &&
      !favourite.selectedVariant
  );

  // Generate sharing URL
  const shareUrl =
    typeof window !== 'undefined' &&
    getUrlPath() +
      '?' +
      decodeURIComponent(
        qs.stringify({
          ...restParams,
          shared: encodeURI(articleNumbers)
        })
      );

  return {
    isLoading: loading,
    favourites,
    isSharedFavourites,
    clearFavourites,
    shouldSelectVariants,
    shareUrl
  };
}

const FavouritesPage = ({ location }) => {
  const {
    isLoading,
    favourites,
    isSharedFavourites,
    clearFavourites,
    shouldSelectVariants,
    shareUrl
  } = useShareFavourites(location);
  const track = useTracker();

  const products = favourites.map(favourite => favourite.product);

  const [isCopied, setIsCopied] = React.useState(false);
  if (isSharedFavourites) {
    return (
      <MaxWidth>
        <PageHeader>Delad favoritlista</PageHeader>
        <PageDescription>
          Här kan du se produkter på favoritlistan och lägga de direkt i
          varukorgen.
        </PageDescription>
        {isLoading ? (
          <CenteredSpinner />
        ) : favourites.length > 0 ? (
          <>
            <div
              style={{
                width: '100%',
                maxWidth: '22rem',
                margin: '0 auto 2rem',
                padding: '0 1rem'
              }}
            >
              <DrawerTrigger id="cart-drawer">
                {cartDrawer => (
                  <AddManyToCartButton
                    products={favourites}
                    callback={() => {
                      cartDrawer.showTarget();
                      track({
                        name: 'ADD_TO_CART_FROM_FAVOURITES',
                        payload: { products: favourites }
                      });
                    }}
                  />
                )}
              </DrawerTrigger>
            </div>

            <ProductGrid products={products} />
          </>
        ) : (
          <NoFavouritesInfo>
            Det verkar inte finnas några produkter på den här listan. Skapa din
            egen lista genom att klicka på ikonen bredvid produktbilderna.
          </NoFavouritesInfo>
        )}
      </MaxWidth>
    );
  } else {
    return (
      <MaxWidth>
        <PageHeader>Mina Favoriter</PageHeader>
        <PageDescription>
          På denna sida visas alla favoriter du lagt till. Här nedan kan du
          enkelt kopiera en länk till dina favoriter du kan dela med andra.
        </PageDescription>
        {isLoading ? (
          <CenteredSpinner />
        ) : favourites.length > 0 ? (
          <>
            {!shouldSelectVariants && (
              <FavouritesLinkSection
                onClick={event => {
                  let target = event.currentTarget.querySelector('input');

                  if (navigator.userAgent.match(/ipad|iphone/i)) {
                    let range = document.createRange();
                    range.selectNodeContents(target);
                    let selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    target.setSelectionRange(0, 999999);
                  } else {
                    target.select();
                  }

                  document.execCommand('copy');
                  setIsCopied(true);
                }}
              >
                <FavouritesLink type="text" readOnly value={shareUrl} />

                <CopiedButton data-copied={isCopied}>
                  {isCopied ? (
                    <>{t('Copied!')}</>
                  ) : (
                    <>
                      {t('Copy')} <StyledIconCopy />
                    </>
                  )}
                </CopiedButton>
              </FavouritesLinkSection>
            )}

            <ProductGrid products={products} />
            <ClearFavouritesButton onClick={clearFavourites}>
              Rensa Favoriter
            </ClearFavouritesButton>
          </>
        ) : (
          <NoFavouritesInfo>
            Lägg till produkter som favoriter genom att klicka på ikonen bredvid
            produktbilderna.
          </NoFavouritesInfo>
        )}
      </MaxWidth>
    );
  }
};

export default withRouter(FavouritesPage);
