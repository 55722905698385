import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Link } from 'react-router-dom';

import { theme } from '../Theme';

const Wrapper = styled('li')`
  display: flex;
  justify-content: space-between;
  background: white;
  border-bottom: 1px solid ${theme.colors.grey};

  ${theme.below.md} {
    flex-direction: column;
  }
`;

const ProductName = styled('div')`
  .variant-value {
    display: block;
  }
`;

const ProductDetailWrapper = styled('section')`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 80%;

  > :first-of-type {
    flex: 0 1 50%;
    max-width: 94px;
  }

  > :last-of-type {
    flex: 1 1 auto;
  }
`;

const ItemDetails = styled('div')`
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const QtyDetails = styled('section')`
  display: flex;
  flex: 0 1 20%;
  align-items: center;
  justify-content: flex-start;

  ${theme.below.md} {
    margin: -${theme.space[2]} 0 ${theme.space[2]} 109px;
  }
`;

const BrandNameTitle = styled('h2')`
  font-weight: ${theme.fontWeights.book};
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  color: ${theme.colors.mediumgrey};
  padding: 0;
`;

const ProductNameTitle = styled('h3')`
  font-weight: ${theme.fontWeights.medium};
  font-size: 1rem;
  color: ${theme.colors.primary};
  padding: ${theme.space[1]} 0;
`;

const LinePrice = styled(Price)``;

const CartItem = ({
  item,
  className = '',
  clearInput,
  handleKeyPress,
  drawer
}) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );

  return item?.primaryRoute?.path ? (
    <Wrapper className={className}>
      <ProductDetailWrapper>
        <div>
          {item.images.length > 0 && (
            <Image
              aspect="1:1"
              sizes="6rem"
              src={hasVariantImage ? variantImage.url : item.images[0].url}
              alt={hasVariantImage ? variantImage.alt : item.images[0].alt}
              quality={80}
            />
          )}
        </div>
        <div>
          <ItemDetails>
            <ProductName>
              <Link
                onClick={() => {
                  clearInput();
                  handleKeyPress(drawer);
                }}
                to={item.primaryRoute.path}
              >
                <BrandNameTitle>{item.subName}</BrandNameTitle>
                <ProductNameTitle>{item.name}</ProductNameTitle>
                {isVariant && (
                  <h3
                    className={css`
                      margin-top: 0.5rem;
                    `}
                  >
                    {variantNamesValues.map((variantOptionNameValue, index) => (
                      <span className="variant-value" key={index}>
                        {variantOptionNameValue}
                      </span>
                    ))}
                  </h3>
                )}
              </Link>
            </ProductName>
          </ItemDetails>
        </div>
      </ProductDetailWrapper>

      <QtyDetails>
        <LinePrice
          data-testid="item-price"
          price={item.price}
          previousPrice={item.previousTotal}
        />
      </QtyDetails>
    </Wrapper>
  ) : null;
};

export default CartItem;
