import React from 'react';
import { Formik, Form } from 'formik';
import InputWithLabel from '../Forms/Input';
import { theme } from '../Theme';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import t from '@jetshop/intl';
import ButtonWithLoading from '../ui/Button';
import { useHistory } from 'react-router-dom';

export const modalStyles = css`
  z-index: 999;
`;

const inputFields = [
  {
    name: 'first_name',
    label: 'Förnamn',
    type: 'text',
    wrapperClassName: 'firstname',
    value: ''
  },
  {
    name: 'last_name',
    label: 'Efternamn',
    type: 'text',
    wrapperClassName: 'lastname',
    value: ''
  },
  {
    name: 'adress',
    label: 'Adress',
    type: 'text',
    wrapperClassName: 'address full-width',
    value: ''
  },
  {
    name: 'postalcode',
    label: 'Postnummer',
    type: 'text',
    wrapperClassName: 'postalcode',
    value: ''
  },
  {
    name: 'city',
    label: 'Stad',
    type: 'text',
    wrapperClassName: 'city',
    value: ''
  },
  {
    name: 'email',
    label: 'Epost',
    type: 'email',
    wrapperClassName: 'email full-width',
    value: ''
  }
];

const Container = styled('div')`
  background: white;
  position: relative;
  border: 1px solid #e8e8e8;
  width: 100%;
  max-width: 55rem;
  margin: 1rem auto;

  ${theme.below.md} {
    max-width: 100%;
    max-height: 80vh;
    overflow: auto;
  }

  .close-modal {
    position: absolute;
    right: 0;

    background: none;
    font-size: 1.1rem;
    padding: 1rem;
    color: ${theme.colors.primary};
  }

  .inner-modal {
    color: ${theme.colors.primary};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 1.5rem 1.5rem;
    ${theme.above.md} {
      padding: 2.5rem 3.5rem;
      header,
      .form {
        width: 80%;
      }
    }

    header {
      width: 100%;
      padding: 0 0.5rem;
    }

    .form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
      width: 100%;
      margin-top: 1.5rem;

      .full-width {
        grid-column: span 2;
      }

      .input-wrapper {
        width: 100%;
        padding: 0 0.5rem;
      }

      ${theme.below.sm} {
        display: block;
        .input-wrapper {
          width: 100%;
          padding: 0;
        }
      }
    }

    .button-container {
      width: 100%;
      display: flex;

      .go-back-button {
        width: 30%;
        margin-right: 0.5rem;
        white-space: nowrap;
        ${theme.below.sm} {
          font-size: 0.575rem;
        }
      }

      .commit-order-button {
        background: ${theme.colors.primary};
        color: white;
        padding: 1rem;
        width: 70%;
        ${theme.below.sm} {
          font-size: 0.675rem;
          max-width: 100%;
        }
      }
    }

    .modal-header {
      transition: opacity 0.1s ease;
      &-visible {
        opacity: 0.1;
      }
    }

    .success-message {
      transition: opacity 0.1s e ease;
      opacity: 0;
      text-align: center;
      max-width: 70%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &-visible {
        opacity: 1;
      }
    }

    .form {
      transition: opacity 0.1s ease;
      opacity: 0.1;
      &-visible {
        opacity: 1;
      }
    }
  }
`;

const initialState = {
  loading: false,
  error: false,
  success: false,
  errorMessage: null
};

function reducer(state, action) {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: true
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.data.errorMessage
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        success: true
      };
    case 'RESET': {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export function OrderForm() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const history = useHistory();
  return (
    <Container>
      <button
        className="close-modal"
        onClick={() => {
          history.goBack();
        }}
      >
        &times;
      </button>
      <div className="inner-modal">
        <header
          className={cx(
            'modal-header',
            state.success && 'modal-header-visible'
          )}
        >
          <h2>{t('Nästan där!')}</h2>
          <p>
            {t(
              'Fyll i dina uppgifter nedan och beställ ditt kostnadsfria guldbrev idag. Du binder dig inte till någonting.'
            )}
          </p>
        </header>
        <Formik
          initialValues={inputFields.reduce((fs, f) => {
            if (!fs[f.name]) {
              fs[f.name] = '';
            }
            return fs;
          }, {})}
          onSubmit={values => {
            async function subscribe() {
              dispatch({ type: 'LOADING' });
              const response = await fetch('/api/subscribe', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
              });

              if (!response.ok) {
                const reason = await response.text();
                return dispatch({
                  type: 'ERROR',
                  data: { errorMessage: reason }
                });
              }

              const data = await response.json();
              dispatch({ type: 'SUCCESS', data });
            }

            subscribe().catch(err => {
              console.log('err', err);
              return dispatch({ type: 'ERROR', data: err });
            });
          }}
        >
          {() => {
            return (
              <>
                <div
                  className={cx(
                    'success-message',
                    state.success && 'success-message-visible'
                  )}
                >
                  <h2>{t('Vi har mottagit ditt förfrågan.')}</h2>
                  <p>
                    {t(
                      'Vi kommer att kontakta dig vidare med mer information.'
                    )}
                  </p>
                </div>
                <Form className={cx('form', !state.success && 'form-visible')}>
                  {inputFields.map(input => {
                    return (
                      <InputWithLabel
                        {...input}
                        key={input.name}
                        disabled={state.success}
                        required
                        onClick={() => {
                          if (state.error) {
                            dispatch({ type: 'RESET' });
                          }
                        }}
                      />
                    );
                  })}
                  <div className="button-container full-width">
                    <ButtonWithLoading
                      className="go-back-button"
                      disabled={state.loading}
                      loading={state.loading}
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t('Tillbaka')}
                    </ButtonWithLoading>
                    <ButtonWithLoading
                      className="commit-order-button"
                      disabled={state.loading}
                      loading={state.loading}
                      type="submit"
                    >
                      {t('Beställ mitt guldbrev')}
                    </ButtonWithLoading>
                  </div>
                </Form>
                {state.errorMessage && (
                  <p
                    style={{
                      textAlign: 'center',
                      marginTop: '0.5rem',
                      color: '#ad2424'
                    }}
                  >
                    {t(state.errorMessage)}
                  </p>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
}
