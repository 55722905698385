import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '../../svg/IconCross.svg';
import { ReactComponent as Plus } from '../../svg/IconPlus.svg';
import { ReactComponent as Minus } from '../../svg/IconMinus.svg';
import { theme } from '../Theme';
import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${theme.space[3]};
  background: white;
  border-bottom: 1px solid ${theme.colors.grey};

  ${theme.below.md} {
    flex-direction: column;
  }
`;

const ProductName = styled('div')`
    .variant-value {
        display: block;
        font-size: 0.875rem;
        font-weight: normal;
    }
`;

const ProductDetailWrapper = styled('section')`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 50%;

  > :first-of-type {
    flex: 0 1 34%;

    ${theme.below.md} {
      max-width: 124px;
      min-width: 124px;
    }
  }

  > :last-of-type {
    flex: 0 1 66%;

    ${theme.below.md} {
      flex: calc(100% - 124px);
    }
  }
`;

const QtyDetails = styled('section')`
  display: flex;
  flex: 0 1 34%;
  align-items: center;
  justify-content: space-between;
`;

const ItemDetails = styled('div')`
  margin-left: 1rem;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const BrandNameTitle = styled('h2')`
  font-weight: ${theme.fontWeights.book};
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  color: ${theme.colors.mediumgrey};
`;

const ProductNameTitle = styled('h3')`
    font-weight: ${theme.fontWeights.medium};
    font-size: 1rem;
    line-height: 1.3;
    color: ${theme.colors.primary};
    padding: ${theme.space[1]} 0;
`;

const LinePrice = styled(Price)``;

const AdjustQty = styled('div')`
  display: flex;
  align-items: center;
  /* Plus/minus buttons */
  button {
    border: 2px solid ${theme.colors.primary};
    border-radius: 50%;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    :focus {
      outline: none;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
  }

  ${theme.below.md} {
    width: 124px;
    justify-content: center;
  }
`;

const RemoveItem = styled('div')`
  button {
    padding: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    outline: none;
    border: none;
    background: none;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    stroke: ${theme.colors.mediumgrey};
    height: 10px;
    width: 10px;
  }
`;

const RemoveItemMobile = styled('div')`
  position: absolute;
  top: 0;
  left: 0;

  button {
    padding: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    outline: none;
    border: none;
    background: ${theme.colors.primary};
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    stroke: ${theme.colors.white};
    height: 12px;
    width: 12px;
  }
`;

const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);
  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );

  return (
    <Wrapper className={className}>
      <ProductDetailWrapper>
        <div>
          {item.product.images.length > 0 && (
            <Image
              aspect="1:1"
              sizes="5rem"
              src={
                hasVariantImage ? variantImage.url : item.product.images[0].url
              }
              alt={
                hasVariantImage ? variantImage.alt : item.product.images[0].alt
              }
              quality={80}
            />
          )}
        </div>
        <Below breakpoint="md">
          {matches =>
            matches && (
              <RemoveItemMobile>
                <button
                  onClick={() =>
                    removeFromCart({ itemId: item.id, product: item.product })
                  }
                  data-testid="remove-from-cart"
                >
                  <Cross />
                </button>
              </RemoveItemMobile>
            )
          }
        </Below>
        <div>
          <ItemDetails>
            <FlyoutTrigger id="cart-flyout">
              {flyout => (
                <ProductName onClick={flyout.hideTarget}>
                  <Link to={item.product.primaryRoute.path}>
                    <BrandNameTitle>{item.product.subName}</BrandNameTitle>
                    <ProductNameTitle>{item.product.name}</ProductNameTitle>
                    {isVariant && (
                      <h3
                        style={{
                          marginTop: '0'
                        }}
                      >
                        {variantNamesValues.map(
                          (variantOptionNameValue, index) => (
                            <span className="variant-value" key={index}>
                              {variantOptionNameValue}
                            </span>
                          )
                        )}
                      </h3>
                    )}
                  </Link>
                </ProductName>
              )}
            </FlyoutTrigger>
          </ItemDetails>
        </div>
      </ProductDetailWrapper>

      <QtyDetails>
        <AdjustQty>
          <button
            data-testid="decrement-quantity"
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            <Minus />
          </button>
          <span data-testid="item-quantity">{item.quantity}</span>
          <button
            data-testid="increment-quantity"
            onClick={() => incrementQuantity({ itemId: item.id })}
          >
            <Plus />
          </button>
        </AdjustQty>

        <LinePrice
          data-testid="item-price"
          price={item.total}
          previousPrice={item.previousTotal}
        />
        <Above breakpoint="md">
          {matches =>
            matches && (
              <RemoveItem>
                <button
                  onClick={() =>
                    removeFromCart({ itemId: item.id, product: item.product })
                  }
                  data-testid="remove-from-cart"
                >
                  <Cross />
                </button>
              </RemoveItem>
            )
          }
        </Above>
      </QtyDetails>
    </Wrapper>
  );
};

export default CartItem;
