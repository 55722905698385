import React from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { ReactComponent as Cart } from '../../svg/IconCart.svg';
import t from '@jetshop/intl';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import cartQuery from './CartQuery.gql';
import Badge from '../ui/Badge';

const CartLabel = styled('label')`
  font-size: 0.65rem;
  margin-top: 7px;
  margin-left: -${theme.space[2]};
  text-transform: uppercase;

  ${theme.below.lg} {
    display: none;
  }
`;

const IconContainer = styled('span')`
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 18px;

  svg {
    margin: 0;
    width: 22px;
    height: 19px;
    stroke: #000;

    use {
      fill: #333;
    }
  }

  .badge {
    position: absolute;
    top: -8px;
    right: 0;
    font-size: 0.75rem;
    font-weight: ${theme.fontWeights.medium};
  }

  cursor: pointer;
`;

export const Button = styled('span')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-left: ${theme.space[1]};
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;

  ${theme.below.lg} {
    margin-left: ${theme.space[2]};
    margin-right: 14px;
  }

  :hover {
    color: ${theme.colors.black};
    svg {
      use {
        fill: ${theme.colors.black};
      }
    }
  }
  svg {
    width: 18px;
    height: 18px;
    margin-bottom: 0;
    use {
      fill: #878787;
    }
  }

  .cart-button-inner {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 0.25em;
    }
  }
  ${theme.below.lg} {
    svg {
      width: 24px;
      height: 24px;
      margin: 0px;
    }
  }

  ${IconContainer} {
    &:hover {
      svg {
        stroke: ${theme.colors.green};
      }
    }

    svg {
      width: 26px;
      height: 22px;
    }
  }
`;

const CartButton = ({ showLabel = true }) => {
  return (
    <CartProvider query={cartQuery}>
      {result => {
        // Set items in cart to the API result.
        // If the result is undefined, fall back to 0
        const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

        return (
          // Use a flyout for breakpoints above md
          // Else use a Drawer
          <DrawerTrigger preventOverflow={true} id="cart-drawer">
            {drawer => (
              <Button
                data-testid="header-cart"
                onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
              >
                <IconContainer>
                  <Badge text={itemsInCart} />
                  <Cart />
                </IconContainer>
                {showLabel && <CartLabel>{t('Cart')}</CartLabel>}
              </Button>
            )}
          </DrawerTrigger>
        );
      }}
    </CartProvider>
  );
};

export default CartButton;
