import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { css } from 'linaria';

const CaptionCSS = css`
  width: 100%;
  max-width: 46rem;
  margin: 0 auto;
  font-size: 0.75rem;
  line-height: 1.375rem;
  text-align: center;
  padding: 1em 0;
`;

const ThemeImage = props => {
  return (
    <>
      <Image {...props} quality={60}>
        {props.children}
      </Image>
      {props.caption && <p style={CaptionCSS}>{props.caption}</p>}
    </>
  );
};

export default ThemeImage;
