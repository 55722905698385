const themeVariables = {
  productsPerPage: 24,
  postsPerPage: 6,
  blogRootId: 157,
  calculator: {
    categoryId: 257,
    articleNumber: 'guldbrev'
  }
};

export default themeVariables;
