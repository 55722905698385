import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const SearchBarContainer = styled('div')`
  position: relative;
  width: 100%;
  svg {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    use {
      fill: #878787;
    }
  }

  width: 0;
  overflow: hidden;
  transition: width 0.4s;
  &.search-open {
    overflow: unset;
    width: 100%;
  }

  [type='search'] {
    border: 0;
    height: 100%;
    padding: 0 0 10px 28px;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.primary};
    outline: none;
    font-size: 1rem;
    background: none;
  }
`;

// const Input = styled.input``;

const SearchBar = ({ searchOpen, autofocus }) => {
  return (
    <SearchBarContainer className={cx(searchOpen && 'search-open')}>
      <input type="search" placeholder="Sök" autoFocus={autofocus} />
    </SearchBarContainer>
  );
};

export default SearchBar;
