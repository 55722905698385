import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.75rem;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 81.5rem;
`;

export const MaxWidthSmall = styled('div')`
  width: 100%;
  max-width: 50rem;
  padding: 0 0.75rem;
  margin: 0 auto;
  ${theme.above.sm} {
    padding: 0 2rem;
  }
`;

export const MaxWidthExtraSmall = styled('div')`
  width: 100%;
  max-width: 38rem;
  padding: 0 0.75rem;
  margin: 0 auto;
  ${theme.above.sm} {
    padding: 0 2rem;
  }
`;

const MaxWidth = ({ className = '', children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default MaxWidth;
