import React from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import pageQuery from './PageQuery.gql';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../Theme';
import { TryggHandelLogo } from '../../Layout/Footer/TryggHandelLogo';

const LogoWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${theme.space[3]} auto 0;
`;

const LogoInner = styled('div')`
  flex: 1 1 100%;
  margin-right: ${theme.space[2]};
  width: ${({ maxWidth }) => (maxWidth ? maxWidth : `68px`)};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : `68px`)};

  &:last-of-type {
    margin-right: 0;
  }

  ${theme.below.md} {
    width: 312px;
  }
`;

const PartnerGroupComponent = ({ children, title }) => (
  <section>
    {title && <h2>{title}</h2>}
    <LogoWrapper>{children}</LogoWrapper>
  </section>
);

const PartnerItem = ({ partnerImage, maxWidth, aspectRatio }) => (
  <LogoInner maxWidth={maxWidth}>
    <Image aspect={aspectRatio ? aspectRatio : '2:1'} src={'/' + partnerImage} />
  </LogoInner>
);

const PartnerGroup = () => {
  const page_id = 53;

  return (
    <Query query={pageQuery} variables={{ page_id }}>
      {({ data, loading }) => {
        if (loading) return '';

        if (data && data.page && data.page.content) {
          let shortCodeContent = data.page.content
            .replace(/<p>|<\/p>|<br>|<br \/>|(\r\n|\n|\r)/g, '')
            .trim();

          return (
            <>
              <Shortcodes
                content={shortCodeContent}
                components={{
                  PartnerGroup: PartnerGroupComponent,
                  PartnerItem
                }}
              />
              <TryggHandelLogo />
            </>
          );
        }
        return null;
      }}
    </Query>
  );
};

export default PartnerGroup;
