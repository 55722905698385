export const storeList = [
  "Webbshop",
  "Alingsås",
  "Arvidsjaur",
  "Avesta",
  "Borås",
  "Eksjö",
  "Fagersta",
  "Frölunda Torg",
  "Gävle",
  "Halmstad",
  "Halmstad Hallarna",
  "Hässleholm",
  "Jönköping",
  "Kalmar",
  "Karlskrona",
  "Karlstad Bergvik",
  "Kristinehamn",
  "Kumla",
  "Kungälv",
  "Mariestad",
  "Motala",
  "Märsta",
  "Mölndal",
  "Norrtälje",
  "Nässjö",
  "Partille",
  "Sala",
  "Sollentuna",
  "Solna",
  "Stenungsund",
  "Stockholm Farsta",
  "Stockholm Haninge",
  "Stockholm Kungens Kurva",
  "Stockholm Skärholmen",
  "Stockholm Sveavägen",
  "Sundsvall",
  "Sölvesborg",
  "Tumba",
  "Täby",
  "Uddevalla",
  "Upplands Väsby",
  "Väla",
  "Vänersborg",
  "Växjö",
  "Örebro Marieberg",
  "Östersund"
];