import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { ReactComponent as IconHeart } from '../../svg/IconHeart.svg';
import t from '@jetshop/intl';
import useFavourites from '../Favourites/useFavourites';
import { theme } from '../Theme';

const ToggleFavouriteCSS = `
  background: transparent;
  width: 25px;
  margin-left: 1rem;
  outline: 0;
  border: 0;
  appearance: none;
  svg {
    > path {
      stroke: ${theme.colors.blueDark};
      .active {
          fill: ${theme.colors.blueDark};
        }
    }
  }
`;

const ToggleFavourite = styled('button')`
  ${ToggleFavouriteCSS}
  &.active {
    svg {
      path {
        fill: black;
      }
    }
  }
`;

const ToggleFavouriteButton = styled('button')`
  ${theme.buttonStyle};

  &.active {
    background: ${theme.colors.green};

    svg {
      > path {
        stroke: ${theme.colors.white};
        fill: ${theme.colors.white};
      }
    }

    &:hover {
      background: ${theme.colors.green};
    }
  }
`;
const ToggleFavouritesProduct = ({ product, isButton = false }) => {
  const { isFavourite, toggleFavourite } = useFavourites();
  const ToggleFavouriteRef = isButton ? ToggleFavouriteButton : ToggleFavourite;

  return (
    <ToggleFavouriteRef
      isButton={isButton}
      onClick={event => {
        event.preventDefault();
        toggleFavourite(product);
      }}
      className={cx(isFavourite(product) && 'active', 'toggle-favourite')}
    >
      {isButton && (
        <span>
          {isFavourite(product)
            ? t('Added as favorite')
            : t('Save as favorite')}
        </span>
      )}
      <IconHeart />
    </ToggleFavouriteRef>
  );
};

export default ToggleFavouritesProduct;
