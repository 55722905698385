import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import { styled } from 'linaria/react';
import UIMaxWidth from '../../MaxWidth';
import AnimateHeight from 'react-animate-height';
import Image from '@jetshop/ui/Image';
import { NavLink } from 'react-router-dom';
import t from '@jetshop/intl';
import { theme } from '../../../Theme';
import {CATEGORY_BRAND_ID} from '../../../Utils/constants'
// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  display: flex;
  flex-direction: row;
  padding-top: ${theme.space[4]};
  padding-bottom: 8rem;
  /* max-width: 1044px; */
  width: auto;
`;

const InnerWrapper = styled('div')`
  border-width: 1px 0 1px 0;
  width: 100%;
  background: #ebeae8;
`;

const Wrapper = styled('section')`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  will-change: height;
  transition: height 0.3s ease-in-out;

  h2 {
    margin-bottom: 0;
    font-weight: 600;
    padding: 0 1rem;
    font-weight: ${theme.fontWeights.medium};
  }

  &[data-scrolled='true'] {
    position: fixed;
    top: 74px;
  }
`;

const NoThirdTierItems = styled('li')`
  font-weight: 500;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;

  .sub-item {
    font-size: 0.75rem;
    font-weight: ${theme.fontWeights.book};
    margin-top: 0.7rem;

    :first-child {
      margin-top: ${theme.space[2]};
    }
    a {
      &.active,
      &:hover {
        &:after {
          height: 1px;
          bottom: -3px;
        }
      }
    }
  }

  a {
    position: relative;
    text-transform: initial;
    max-width: fit-content;

    &.active,
    &:hover {
      text-decoration: none;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background: ${theme.colors.black};
      }
    }
  }
`;

const ProductSection = styled('div')`
  /* flex: 0 1 44%; */
`;

const NavigationSection = styled('div')`
  display: flex;
  flex-wrap: wrap;

  a {
    line-height: 1.5;
  }

  &[data-multicolumn='true'] {
    width: 100%;
    display: block;
    padding-left: ${theme.space[8]};
    column-count: 5;

    ul {
      padding: 0 0.5rem 0.75rem 0.5rem;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }

  &[data-multicolumn='false'] {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: ${theme.space[8]};

    > ul {
      padding: 0 0.5rem 0.75rem 0.5rem;
      width: 20%;

      ul {
        width: 100%;
      }
    }
  }
`;

const CategoryImage = styled(Image)`
  position: absolute;
`;

const ImageTitle = styled('h3')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 1.5rem;
  text-align: center;
  font-size: 0.85rem;
  line-height: 2.8rem;
  font-weight: ${theme.fontWeights.medium};
  white-space: nowrap;
  background: ${theme.colors.greyLight};

  ${theme.below.md} {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    font-size: 1.5rem;
    line-height: 1.625rem;
  }
`;

const MenuImageWrapper = styled('div')`
  position: relative;
  padding: 0 !important;
`;

const SubMenuWrapper = ({
  clearActiveCategories,
  setHoverMenu,
  pose,
  activeCategory,
  activeCategories,
  closeNav,
  scrolled,
}) => {
  const SubMenuColumns = ({ itemsPerColumn }) => {
    let subMenuTree = [[]];
    let thisColumn = 0;
    let index = 0;

    // let subMenuItems = 0;

    activeCategory &&
      activeCategory.subcategories.forEach(cat => {
        if (index > itemsPerColumn - 1) {
          thisColumn++;
          index = 0;
          subMenuTree[thisColumn] = [];
        }
        subMenuTree[thisColumn][index] = cat;

        index++;
        // subMenuItems++;
      });

    const columns = subMenuTree.map((column, index) => {
      const items = column.map(item => {
        const isBrandCategory = item.parentId !== CATEGORY_BRAND_ID

        return (
          <NoThirdTierItems key={item.id}>
            <CategoryLink
              category={item}
              onClick={() => {
                closeNav();
                setHoverMenu(false);
              }}
            >
              {item.name}
            </CategoryLink>
            {isBrandCategory && item.subcategories && item.subcategories.length > 0 && (
              <ul>
                {item.subcategories.map(subItem => (
                  <li className="sub-item" key={item.id + subItem.name}>
                    <CategoryLink
                      category={subItem}
                      onClick={() => {
                        closeNav();
                        setHoverMenu(false);
                      }}
                    >
                      {subItem.name}
                    </CategoryLink>
                  </li>
                ))}
              </ul>
            )}
          </NoThirdTierItems>
        )
      });

      return <ul key={index}>{items}</ul>;
    });

    return columns;
  };

  const isMulticolumnLayout =
    activeCategory && activeCategory.subcategories.length > 20;

  return (
    <Wrapper data-scrolled={scrolled}>
      <InnerWrapper
        onMouseLeave={() => {
          clearActiveCategories();
          activeCategories.length > 0 && setHoverMenu(false);
        }}
      >
        <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
          <MaxWidth>
            <NavigationSection
              data-multicolumn={isMulticolumnLayout}
              isMulticolumnLayout={isMulticolumnLayout}
            >
              <SubMenuColumns itemsPerColumn={1} />
            </NavigationSection>
            {!isMulticolumnLayout && (
              <ProductSection>
                {activeCategory &&
                  activeCategory.images &&
                  activeCategory.images.length > 0 && (
                    <MenuImageWrapper>
                      <CategoryImage
                        key={activeCategory.images[0].src}
                        src={activeCategory.images}
                        aspect="394:171"
                        cover={true}
                      />
                      <NavLink to={activeCategory.primaryRoute.path}>
                        <ImageTitle>
                          {t('Allt inom')} {activeCategory.name}
                        </ImageTitle>
                      </NavLink>
                    </MenuImageWrapper>
                  )}
              </ProductSection>
            )}
          </MaxWidth>
        </AnimateHeight>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SubMenuWrapper;
