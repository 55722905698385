import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import { Below } from '@jetshop/ui/Breakpoints';
import { css, cx } from 'linaria';
import React, { useEffect } from 'react';
import { theme } from '../Theme';
import CarouselMain from '../Theme/Carousel';
import { ProductCard } from './ProductCard';

export const priceStyle = css`
  [data-flight-price] {
    display: flex;
    font-weight: 400;
    white-space: nowrap;

    ${theme.below.sm} {
      font-size: 14px;
    }

    .price {
      order: 1;
      margin-right: 1.5rem;
    }
    .old-price {
      order: 2;
      position: relative;
      color: ${theme.colors.mediumgrey};
      text-decoration: none;
      margin-left: 0.5rem;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: ${theme.colors.black};
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
`;

const WrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -${theme.space[1]};
  margin-left: -${theme.space[1]};

  .product-card {
    width: 50%;
    min-width: 0;
    line-height: 19px;

    ${theme.above.lg} {
      width: 25%;
    }

    a {
      margin: 0 0.5em 2em 0.5em;
    }
  }

  ${theme.below.md} {
    margin: 0 auto;

    .product-card {
      > a {
        margin: 0;
      }
    }
  }

  .slider-card {
    width: 100%;
  }
`;

const OffsetCSS = css`
  ${theme.above.lg} {
    min-height: 400px;

    > li {
      &:first-of-type {
        margin-left: 50%;
      }
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  carousel,
  testprop,
  useOffset = false,
  as = 'ul',
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  const productItems = products.map((product, index) => {
    return (
      <ProductCard
        key={index + ':' + product.articleNumber}
        product={product}
        cardStyle={{ opacity: loading ? 0.5 : 1 }}
        {...rest}
        carousel={carousel}
        as={carousel ? 'div' : 'li'}
      />
    );
  });

  const Tag = as;
  const UseOffsetCSS = useOffset ? OffsetCSS : null;

  return (
    <Tag
      data-testid="product-grid"
      className={cx(
        'product-grid',
        className,
        WrapperCSS,
        UseOffsetCSS,
        priceStyle
      )}
    >
      {carousel ? (
        <Below breakpoint="md">
          {matches => (
            <CarouselMain
              showItems={matches ? 2 : 4}
              slideItems={matches ? 2 : 4}
              sliderType={'ul'}
              sliderItemType={'li'}
            >
              {productItems}
            </CarouselMain>
          )}
        </Below>
      ) : (
        <>{productItems}</>
      )}
    </Tag>
  );
}

export default ProductGrid;
