import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theme';
import get from 'lodash.get';
import React from 'react';
import { styled } from 'linaria/react';

import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';

// const StyledCross = styled(Cross)`
//   position: absolute;
//   left: 24px;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 18px;
//   height: 20px;
//   stroke: ${theme.colors.black};
// `;

const Flyout = styled('div')`
  height: 100%;
  background: white;
  color: ${theme.colors.black};
  padding-top: 62px;
  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    width: 100%;
    min-width: 19rem;
    max-width: 100%;
  }
`;

const LightText = styled('span')`
  color: #828282;
  font-size: 0.875rem;
  font-family: 400;
  font-weight: normal;
`;

const Header = styled('header')`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${theme.colors.greyLight};
  h2 {
    font-size: 1.25rem;
    font-weight: ${theme.fontWeights.medium};
    text-align: center;
  }
  ${LightText} {
    display: block;
  }
  padding: 0 2rem;
  border-bottom: 1px solid ${theme.colors.grey};
  height: 62px;
`;

// const ItemCount = ({ count }) => (
//   <LightText>
//     {t(
//       `{
//         count, plural,
//         =0 {}
//         one {1 item}
//         other {{count} items}
//             }`,
//       { count }
//     )}
//   </LightText>
// );

const CartItems = styled('section')`
  height: calc(100% - 110px);
  overflow: auto;
`;

const Summary = styled('section')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.25rem;
  background: ${theme.colors.greyLight};
  border-top: 1px solid ${theme.colors.grey};

  a {
    width: 100%;
    text-transform: uppercase;
    color: white;
    :hover {
      color: white;
    }
  }

  h2 {
    font-weight: ${theme.fontWeights.medium};
    font-size: 1.2em;
  }

  .discounts {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }

  .cart-total {
    display: flex;
    align-items: center;
    padding: 0 0.75em;
    height: 56px;
    width: 100%;

    .shipping,
    .total {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .total {
      font-size: 1.25rem;
      color: ${theme.colors.primary};
    }
  }
`;

// const Flex = styled('div')`
//   display: flex;
//   justify-content: space-between;
// `;

const Checkout = styled('a')`
  transition: 0.2s background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background: ${theme.colors.green};
  color: ${theme.colors.white};
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background: ${theme.colors.greenHover};
  }
`;

const ContinueShop = styled('div')`
  transition: 0.2s background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background: ${theme.colors.green};
  color: ${theme.colors.white};
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background: ${theme.colors.greenHover};
  }
`;

const DrawerWrapper = styled('div')`
  > div {
    max-width: calc(100% - 32px);
    transition-timing-function: cubic-bezier(0.47, -0.02, 0.15, 0.98);
    transition-duration: 0.6s;
  }
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = get(result, 'data.cart.items', []);
  // const itemCount = get(result, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(result, 'data.cart.externalCheckoutUrl');
  // const discounts = get(result, 'data.cart.aggregatedDiscounts', []);
  const track = useTracker();

  if (items.length === 0) {
    return (
      <Flyout {...rest}>
        <Header>
          <h2>{t('Your cart')}</h2>
          {/* <ItemCount count={itemCount} /> */}
        </Header>
        <div style={{ padding: '2em' }}>{t('No items in cart.')}</div>

        <Summary>
          <div className="cart-total">
            <DrawerTrigger preventOverflow={true} id="cart-drawer">
              {drawer => (
                <ContinueShop
                  data-testid="header-cart"
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  {t('Continue shop')}
                </ContinueShop>
              )}
            </DrawerTrigger>
          </div>
        </Summary>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <DrawerTrigger preventOverflow={true} id="cart-drawer">
        {drawer => (
          <Header>
            {/* <StyledCross onClick={drawer.hideTarget} /> */}
            <h2>{t('Your cart')}</h2>
            {/* <ItemCount count={itemCount} /> */}
          </Header>
        )}
      </DrawerTrigger>
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems>

      <Summary>
        {/* {discounts.length > 0 && (
          <div className="discounts">
            <h2>{t('Cart total')}</h2>
            <Flex>
              <label>{t('Order value')}</label>
              <Price price={result.data.cart.productPreviousTotal} />
            </Flex>
            <h2 css={{ marginTop: '1em' }}>{t('Discounts')}</h2>
            {discounts.map(discount => {
              return (
                <Flex key={discount.name} css={{ marginTop: '1em' }}>
                  <label>{discount.name}</label>
                  <Price price={discount.value} css={{ display: 'inline' }} />
                </Flex>
              );
            })}
          </div>
        )} */}

        <div className="cart-total">
          {/* <FreeShipping
            className="shipping"
            cartTotal={result.data.cart.productTotal}
          /> */}

          <div className="total">
            <label>{t('Total')}</label>
            <Price price={result.data.cart.productTotal} />
          </div>
        </div>
        {checkoutUrl && (
          <Checkout
            href={checkoutUrl}
            onClick={() => {
              track(trackCartCheckoutEvent({ cart: result.data.cart }));
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
      </Summary>
    </Flyout>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <DrawerTarget id="cart-drawer">
          {drawer => (
            <DrawerWrapper>
              <Drawer size={'720'} isOpen={drawer.isOpen} right>
                <CartFlyoutView modal={drawer} result={result} {...props} />
              </Drawer>
            </DrawerWrapper>
          )}
        </DrawerTarget>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
