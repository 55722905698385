import Link from '@jetshop/ui/CategoryLink';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import MobileSubCategories from './MobileSubCategories';
import { ReactComponent as Chevron } from '../../../../svg/IconChevron.svg';
import { ReactComponent as IconSmycke } from '../../../../svg/IconSmycke.svg';
import { ReactComponent as IconInspiration } from '../../../../svg/IconInspiration.svg';
import { ReactComponent as IconBrand } from '../../../../svg/IconBrand.svg';
import { ReactComponent as IconWatch } from '../../../../svg/IconWatch.svg';
import { ReactComponent as IconCampain } from '../../../../svg/IconCampain.svg';
import { ReactComponent as IconSustainability } from '../../../../svg/IconSustainability.svg';

import { theme } from '../../../Theme';

const StyledIconSmycke = styled(IconSmycke)`
  width: 22px;
  height: 22x;
  margin-right: ${theme.space[2]};
  stroke: ${theme.colors.primary};
`;

const StyledIconInspiration = styled(IconInspiration)`
  width: 22px;
  height: 22px;
  margin-right: ${theme.space[2]};
  stroke: ${theme.colors.primary};
`;

const StyledIconBrand = styled(IconBrand)`
  width: 22px;
  height: 24px;
  margin-right: ${theme.space[2]};
  stroke: ${theme.colors.primary};
`;

const StyledIconWatch = styled(IconWatch)`
  width: 22px;
  height: 24px;
  margin-right: ${theme.space[2]};
  stroke: ${theme.colors.primary};
`;

const StyledIconCampain = styled(IconCampain)`
  width: 22px;
  height: 18px;
  margin-right: ${theme.space[2]};
  stroke: ${theme.colors.primary};
`;

const StyledIconSustainability = styled(IconSustainability)`
  width: 22px;
  height: 24px;
  margin-right: ${theme.space[2]};
  stroke: ${theme.colors.primary};
`;

const StyledChevron = styled(Chevron)`
  height: 14px;
  width: 8px;
  transform: rotateZ(180deg);
  stroke: ${theme.colors.primary};
`;

const CategoryTree = styled('ul')`
  display: flex;
  flex-direction: column;
  list-style: none;

  flex: 1 0 auto;

  a,
  a:hover {
    text-decoration: none;
  }
`;

const CategoryLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 1em 1em 0.9375em; /* Adjust for crappy web-font height */
  border-bottom: 1px solid #e7e4e4;
  ${StyledCarrot} {
    display: none;
    margin-right: 0px;
  }

  &.with-chevron {
    ${StyledCarrot} {
      display: block;
    }
  }

  position: relative;

  ul li:last-child &,
  ul li ul li:last-child & {
    border-bottom: none;
  }
`;

const CategoryName = styled('h2')`
  font-size: 16px;
  font-weight: ${theme.fontWeights.medium};
`;

// const SubCategory = styled('li')`
//   font-size: 0.875rem;
// `;

const RootCategory = styled('li')`
  display: flex;
  flex-direction: column;

  &:last-of-type {
    border-bottom: 1px solid #e7e4e4;
  }
`;

const IconWrapper = css`
  display: flex;
  align-items: center;
`;

const IconFallback = styled('span')`
  display: none;
`;

const MobileCategories = ({ categories, closeMenu, isOpen, setIsSubOpen }) => {
  const icons = {
    1: StyledIconCampain,
    154: StyledIconWatch,
    157: StyledIconInspiration,
    162: StyledIconBrand,
    166: StyledIconSmycke,
    167: StyledIconSustainability
  };

  const handleClick = (e, hasChildren, index, closeMenu) => {
    // If there are subcategories, prevent following the link
    if (hasChildren) {
      e.stopPropagation();
      e.preventDefault();
      setActiveSubMenu(index);
      setIsSubOpen(true);
    } else {
      closeMenu();
    }
  };

  // const handleClick = (e, hasChildren, index, closeMenu) => {
  //   // If there are subcategories, prevent following the link
  //   if (hasChildren) {
  //     e.stopPropagation();
  //     e.preventDefault();
  //     setActiveSubMenu(index);
  //     setTimeout(() => {
  //       setIsSubOpen(true);
  //     }, 350);
  //   } else {
  //     closeMenu();
  //   }
  // };

  const [activeSubMenu, setActiveSubMenu] = React.useState(false);

  React.useEffect(() => {
    setActiveSubMenu(isOpen);
    setIsSubOpen(false);
  }, [isOpen, setIsSubOpen]);

  if (categories && categories.length > 0) {
    return (
      <CategoryTree>
        {categories
          .filter(item => item.level === 1)
          .map((cat, index) => {
            let classNames = [];
            // Give categories with children a chevron
            cat.subcategories.length && classNames.push('with-chevron');

            const MenuIcon = icons[cat.id] ? icons[cat.id] : IconFallback;

            return (
              <RootCategory key={cat.id}>
                <CategoryName>
                  <CategoryLink
                    category={cat}
                    onClick={event => {
                      handleClick(
                        event,
                        cat.subcategories.length,
                        index,
                        closeMenu
                      );
                    }}
                    className={
                      classNames.length > 0 ? classNames.join(' ') : null
                    }
                  >
                    <span className={IconWrapper}>
                      <MenuIcon />
                      {cat.name}
                    </span>
                    {cat.subcategories.length ? <StyledChevron /> : null}
                  </CategoryLink>
                </CategoryName>
                {cat.subcategories.length ? (
                  <MobileSubCategories
                    category={cat}
                    name={cat.name}
                    subcategories={cat.subcategories}
                    closeMenu={closeMenu}
                    index={index}
                    activeSubMenu={activeSubMenu}
                    setActiveSubMenu={setActiveSubMenu}
                    setIsSubOpen={setIsSubOpen}
                  />
                ) : null}
              </RootCategory>
            );
          })}
      </CategoryTree>
    );
  } else return null;
};

export default MobileCategories;
