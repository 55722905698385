import React from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import pageQuery from './PageQuery.gql';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import { Link } from 'react-router-dom';
import Tag from './Tag';
import { decodeHTML } from '../Utils/common';
import { ReactComponent as IconFacebook } from '../../../svg/IconFacebook.svg';
import { ReactComponent as IconInstagram } from '../../../svg/IconInstagram.svg';

export const StyledIconInstagram = styled(IconInstagram)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const StyledIconFacebook = styled(IconFacebook)`
  width: 11px;
  height: 20px;
  margin-right: 17px;
`;

const FallBackIcon = styled('span')``;

const LinkGroup = ({ onlySocial = false }) => {
  const page_id = 51;

  const LinkGroup = ({ children, title }) => (
    <>
      {onlySocial ? (
        <>{title === 'Sociala medier' && <div>{children}</div>}</>
      ) : (
        <section>
          <h2>{title}</h2>
          {children}
        </section>
      )}
    </>
  );

  const LinkItem = ({ linkText, linkUrl = '#', linkIcon = false }) => {
    const icons = {
      instagram: StyledIconInstagram,
      facebook: StyledIconFacebook
    };

    const SocialIcon = icons[linkIcon] || FallBackIcon;

    return (
      <>
        {onlySocial ? (
          <a
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={linkText}
          >
            {linkIcon && <SocialIcon className="social-icon" />}
          </a>
        ) : (
          <>
            {linkIcon ? (
              <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                {linkIcon && <SocialIcon />}

                {decodeHTML(linkText)}
              </a>
            ) : (
              <Link to={linkUrl}>{decodeHTML(linkText)}</Link>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Query query={pageQuery} variables={{ page_id }}>
      {({ data, loading }) => {
        if (loading) return '';

        if (data && data.page && data.page.content) {
          let shortCodeContent = data.page.content
            .replace(/<p>|<\/p>|<br>|<br \/>|(\r\n|\n|\r)/g, '')
            .trim();

          return (
            <Shortcodes
              content={shortCodeContent}
              components={{
                LinkGroup,
                LinkItem,
                Tag
              }}
            />
          );
        }
        return null;
      }}
    </Query>
  );
};

export default LinkGroup;
