import { styled } from 'linaria/react';
import { theme } from '../../Theme';

export default styled('main')`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  :not(.start &) {
    padding: 0 2.5rem 0rem 2.5rem;
  }
  ${theme.below.sm} {
    :not(.start &) {
      padding: 0 0.2rem;
    }
  }
`;
