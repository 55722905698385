import React from 'react';
import { Below } from '@jetshop/ui/Breakpoints';
import { css } from 'linaria';
import { ReactComponent as SmyckaLogo } from '../../../svg/SmyckaLogo.svg';

const LogoContainerSmallCSS = css`
  svg {
    width: 132px;
    height: 38px;
  }
`;

const LogoContainerBigCSS = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 168px;
  height: 47px;

  svg {
    width: 168px;
    height: 47px;
  }
`;

const Logo = () => (
  <Below breakpoint="lg">
    {matches =>
      matches ? (
        <div className={LogoContainerSmallCSS}>
          <SmyckaLogo />
        </div>
      ) : (
        <div className={LogoContainerBigCSS}>
          <SmyckaLogo />
        </div>
      )
    }
  </Below>
);

export default Logo;
