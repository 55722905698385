import React, { useContext, useState } from 'react';
import { addToCart as AddToCartMutation } from '@jetshop/core/data/mutations/cartMutations.gql';
import { Mutation } from 'react-apollo';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import UIButton from '../../ui/Button';
import { theme } from '../../Theme';
import {
  addToCartSuccess,
  addToCartError
} from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackProductEvent } from '@jetshop/core/analytics/tracking';
import Spinner from '../../ui/Spinner';

const Button = styled(UIButton)`
  width: 100%;
  padding: 12px 12px 12px 12px;
  letter-spacing: 0.05em;
  font-size: 0.6875rem;
  line-height: normal;
  height: 40px;
  text-transform: uppercase;

  display: block;
  text-align: center;
  outline: none;
  color: ${theme.colors.white};
`;

const AddManyToCartButton = ({
  disabled,
  products,
  quantity = 1,
  title,
  callback = () => null
}) => {
  const { cartId, setCartId } = useContext(CartIdContext);

  const track = useTracker();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Mutation mutation={AddToCartMutation}>
      {mutate => (
        <Button
          disabled={disabled || isLoading}
          onClick={() => {
            setIsLoading(true);
            let checkoutUrl = '';
            const promises = products.reduce(
              (promise, { selectedVariant, product }) =>
                promise.then(cartId =>
                  mutate({
                    variables: {
                      input: {
                        cartId,
                        articleNumber: (selectedVariant || product)
                          .articleNumber,
                        quantity,
                        comments: []
                      }
                    }
                  }).then(
                    response => {
                      addToCartSuccess({
                        onAddToCartSuccess: () =>
                          track(
                            trackProductEvent({
                              action: 'add',
                              product,
                              selectedVariation: selectedVariant
                            })
                          ),
                        cartId,
                        setCartId
                      })(response);
                      checkoutUrl =
                        response.data.addToCart.cart.externalCheckoutUrl;

                      return response.data.addToCart.cart.id;
                    },
                    () => addToCartError({})
                  )
                ),
              Promise.resolve(cartId)
            );
            return promises.then(
              () => {
                setIsLoading(false);
                callback(checkoutUrl, setIsLoading);
              },
              err => {
                setIsLoading(false);
                callback(err);
              }
            );
          }}
        >
          {isLoading ? (
            <Spinner width="16" height="16" />
          ) : (
            t(title || 'Add to cart')
          )}
        </Button>
      )}
    </Mutation>
  );
};

export default AddManyToCartButton;
