import React from 'react';
import { styled } from "linaria/react";

import { theme } from "../Theme";

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 2px solid ${theme.colors.white};
    border-radius: 50%;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: ${theme.colors.white};
    background-color: ${theme.colors.blueDark};
    overflow: hidden;
  }
`;

const Badge = ({ text }) => {
  return (
    <Wrapper className="badge">
      <span>{text}</span>
    </Wrapper>
  );
};

export default Badge;
