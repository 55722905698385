import React from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import pageQuery from './PageQuery.gql';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import { ReactComponent as IconCheck } from '../../../svg/IconCheck.svg';
import { theme } from '../../Theme';

const StyledIconCheck = styled(IconCheck)`
  width: 10px;
  height: 10px;
  margin-right: 6px;
  stroke: ${theme.colors.white};
`;

const StyledUspItem = css`
  display: inline-flex;
  align-items: center;
  height: 16px;
  font-size: 0.75rem;
  font-weight: ${theme.fontWeights.book};
`;

const UspWrapper = ({ children }) => children;

const UspItem = ({ text }) => (
  <span className={StyledUspItem}>
    <StyledIconCheck />
    {text}
  </span>
);

const UspBar = () => {
  const page_id = 52;

  return (
    <Query query={pageQuery} variables={{ page_id }}>
      {({ data, loading }) => {
        if (loading) return '';

        if (data && data.page && data.page.content) {
          let shortCodeContent = data.page.content
            .replace(/<p>|<\/p>|<br>|<br \/>|(\r\n|\n|\r)/g, '')
            .trim();

          return (
            <Shortcodes
              content={shortCodeContent}
              components={{
                UspWrapper,
                UspItem
              }}
            />
          );
        }
        return null;
      }}
    </Query>
  );
};

export default UspBar;
