import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { css, cx } from 'linaria';
import ThemeImage from '../Theme/ThemeImage';
import { theme } from '../Theme';
import { Price } from '../Price';
import ToggleFavouritesProduct from '../Favourites/ToggleFavouritesProduct';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;

  a {
    display: flex;
    flex-direction: column;
    height: calc(100% - 32px);
    color: ${theme.colors.primary};
    text-decoration: none;
    z-index: 1;

    > div {
      position: relative;
      background: white;
    }
  }

  .product-card-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100%; */
    height: auto;
    width: 100%;
    padding: 0 1.125rem;
    margin-top: 0.875rem;

    h3,
    h4 {
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
      font-weight: ${theme.fontWeights.book} !important;
    }

    h4 {
      font-weight: normal;
    }

    &.post {
      h3 {
        width: 100%;
        max-width: 14.5rem;
        margin: 0 auto;
        text-align: center;
        font-size: 1.25rem;
        line-height: 1.875rem;
        font-weight: ${theme.fontWeights.medium};
      }
    }
  }
`;

export function ProductCard({
  product,
  className = '',
  imageAspect = '1:1',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  forwardRef,
  as = 'li',
  blogType,
  carousel,
  slider = false,
  style = {},
  cardStyle = {},
  ...linkProps
}) {
  const hasImages = product?.images && product.images.length > 0;
  const Tag = as;

  let mousePositionDown = 0;
  let mousePositionUp = 0;

  return (
    <>
      {blogType ? (
        <Tag
          className={cx(wrapperStyling, className, 'product-card')}
          data-testid="product"
          data-product-card
          ref={forwardRef}
        >
          <ProductLink
            style={{ margin: '0 1em 1em' }}
            product={product}
            {...linkProps}
          >
            {hasImages ? (
              <ThemeImage
                cover
                sizes={imageSizes}
                aspect="13:9"
                alt={
                  product.images[1]
                    ? product.images[1].alt
                    : product.images[0].alt
                }
                src={
                  product.images[1]
                    ? product.images[1].url
                    : product.images[0].url
                }
              />
            ) : (
              <ThemeImage />
            )}
            <section className={`product-card-detail post`}>
              <header>
                <h3>{product.name}</h3>
              </header>
            </section>
          </ProductLink>
        </Tag>
      ) : (
        <Tag
          className={cx(
            'product-card',
            slider && 'slider-card',
            wrapperStyling,
            className
          )}
          data-testid="product"
          data-product-card
          ref={forwardRef}
          style={style}
        >
          <ProductLink
            product={product}
            style={cardStyle}
            {...linkProps}
            onMouseDown={event => {
              event.preventDefault();
              mousePositionDown = event.clientX;
            }}
            onMouseUp={event => {
              mousePositionUp = event.clientX;
            }}
            onClick={event => {
              if (carousel && mousePositionDown !== mousePositionUp) {
                event.preventDefault();
              }
            }}
          >
            {hasImages ? (
              <div>
                <ThemeImage
                  sizes={imageSizes}
                  aspect={imageAspect}
                  alt={product.images[0].alt}
                  src={product.images[0].url}
                >
                  <Badges badges={product.badges} />
                </ThemeImage>
                <ToggleFavouritesProduct product={product} />
              </div>
            ) : (
              <ThemeImage />
            )}
            <section className={`product-card-detail`}>
              <header>
                {product.subName && (
                  <h4 className="sub-name">{product.subName}</h4>
                )}
                <h3>{product.name}</h3>
              </header>
              <Price
                price={product.price}
                previousPrice={product.previousPrice}
              />
            </section>
          </ProductLink>
        </Tag>
      )}
    </>
  );
}
