import { styled } from "linaria/react";

export default styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex: 1 1 auto;
`;

export const ContainerFullwidth = styled('div')`
  flex: 1 1 100%;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;
