import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { ReactComponent as IconStoreFind } from '../../svg/IconStoreFind.svg';

const Button = styled('span')`
  position: relative;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24px;
  margin-right: ${({ showLabel }) => (showLabel ? `52px` : `12px`)};

  label {
    margin-top: 4px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.65rem;
    white-space: nowrap;
    cursor: pointer;
  }
`;

// TODO: Make a dynamic icon component
const StoreFinderButton = ({ showLabel = true }) => {
  return (
    <Link to="/butiker">
      <ButtonWrapper showLabel={showLabel}>
        <Button>
          <IconStoreFind />
        </Button>
        {showLabel && <label>{t('Stores')}</label>}
      </ButtonWrapper>
    </Link>
  );
};

export default StoreFinderButton;
