import { styled } from 'linaria/react';
import React from 'react';
import t from '@jetshop/intl';
import { theme } from '../Theme';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import useFavourites from './useFavourites';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { ReactComponent as IconHeart } from '../../svg/IconHeart.svg';

const clickDrawer = drawer => {
  let fn = drawer.showTarget;
  if (drawer.isOpen) {
    fn = drawer.hideTarget;
  }
  fn();
};

const Button = styled('span')`
  position: relative;
  cursor: pointer;

  svg {
    stroke: ${theme.colors.blueDark};
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled('span')`
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: -8px;
  right: -20px;
  width: 24px;
  height: 24px;
  border: 2px solid ${theme.colors.white};
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: ${theme.fontWeights.medium};
  line-height: 0.75rem;
  color: ${theme.colors.white};
  background-color: ${theme.colors.blueDark};
  overflow: hidden;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22px;
  margin-right: ${({ showLabel }) => (showLabel ? `46px` : `36px`)};

  ${theme.below.lg} {
    margin-right: 10px;
  }

  label {
    margin-top: 6px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.65rem;
    white-space: nowrap;

    ${theme.below.lg} {
      display: none;
    }
  }
`;

const FavouritesMenuButton = ({ showLabel = true }) => {
  const { totalFavourites } = useFavourites();
  const track = useTracker();
  return (
    <React.Fragment>
      <DrawerTrigger
        preventOverflow={true}
        id="favourites-drawer"
        coverStyles={{ zIndex: 2000 }}
      >
        {drawer => (
          <ButtonWrapper showLabel={showLabel}>
            <Button
              onClick={e => {
                clickDrawer(drawer);
                track({
                  name: 'OPEN_FAVOURITES_MENU'
                });
              }}
            >
              <IconHeart />

              {totalFavourites > 0 && <Indicator>{totalFavourites}</Indicator>}
            </Button>
            {showLabel && <label>{t('Favorites')}</label>}
          </ButtonWrapper>
        )}
      </DrawerTrigger>
    </React.Fragment>
  );
};

export default FavouritesMenuButton;
