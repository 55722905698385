import React from 'react';
import { styled } from 'linaria/react';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';

const LoadableFavouritesFlyoutView = loadable(
  () => import('./FavouritesFlyoutView'),
  {
    fallback: LoadingPage
  }
);

const DrawerWrapper = styled('div')`
  > div {
    max-width: calc(100% - 32px);
    transition-timing-function: cubic-bezier(0.47, -0.02, 0.15, 0.98);
    transition-duration: 0.6s;
  }
`;

const FavouritesFlyout = props => (
  <DrawerTarget id="favourites-drawer">
    {drawer => {
      return (
        <DrawerWrapper>
          <Drawer size="720" isOpen={drawer.isOpen} right>
            <LoadableFavouritesFlyoutView drawer={drawer} {...props} />
          </Drawer>
        </DrawerWrapper>
      );
    }}
  </DrawerTarget>
);

export default FavouritesFlyout;
