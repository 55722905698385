import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Link } from 'react-router-dom';
import MaxWidth from '../MaxWidth';
import { theme } from '../../Theme';
import { ReactComponent as LogoSmycka } from '../../../svg/LogoSmycka.svg';
import LinkGroup from '../../Theme/Shortcodes/LinkGroup';
import PartnerGroup from '../../Theme/Shortcodes/PartnerGroup';

const LogoWrapper = styled('div')`
  width: 168px;
  height: 47px;

  a {
    display: block;
    color: ${theme.colors.white};
  }

  svg {
    width: 168px;
    height: 47px;
    fill: ${theme.colors.white};
  }

  ${theme.below.md} {
    width: 132px;
    height: 38px;

    svg {
      width: 132px;
      height: 38px;
      fill: ${theme.colors.white};
    }
  }
`;

const WrapperHeadCSS = css`
  display: flex;
  justify-content: center;
  padding: 3rem 0 3rem;
  background: ${theme.colors.primary};
  color: #fff;

  ${theme.below.md} {
    padding: 2rem 0 2rem;
  }
`;

const WrapperMainCSS = css`
  text-align: left;
  font-size: 1rem;
  padding: 0 2rem 4rem;
  background: ${theme.colors.primary};
  color: #fff;

  h2 {
    font-weight: ${theme.fontWeights.medium};
    margin-bottom: 0.5rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a,
  p {
    padding: 0.5rem 0;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-weight: 400;
  }

  ${theme.below.md} {
    align-items: flex-start;
  }
`;

const WrapperFoot = styled('section')`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${theme.colors.white};
  color: #fff;

  ${theme.below.md} {
    align-items: flex-start;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 80rem;
    text-align: center;
    margin-bottom: 1.2rem;

    ${theme.below.md} {
      flex-direction: column;
      width: 100%;
    }

    section {
      h2 {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        opacity: 0.75;
      }

      ${theme.below.md} {
        flex: 0 1 100%;
      }
    }
  }

  /* Modal style */
  + div {
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
`;

const WrapFooterNav = styled(MaxWidth)`
  max-width: 90rem;
  padding: 2rem;

  > section {
    order: 1;
    margin: 0 2rem 0 0;

    span {
      display: block;
      margin: ${theme.space[1]} 0;

      &:first-of-type {
        margin-top: ${theme.space[2]};
      }
    }

    ${theme.below.md} {
      &:last-of-type {
        order: 0;
      }
    }
  }

  display: flex;
  justify-content: space-between;
  flex-direction: row;

  ${theme.below.md} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;

    > section {
      order: 1;
      text-align: left;
      margin: 0;
      padding: ${theme.space[2]} 0;
    }
  }

  h2 {
    font-size: 0.75rem;
    font-weight: ${theme.fontWeights.medium};
    text-transform: uppercase;
    opacity: 0.75;
  }
`;

const Footer = () => (
  <>
    <section className={WrapperHeadCSS}>
      <LogoWrapper>
        <Link to="/" aria-label="Smycka">
          <LogoSmycka />
        </Link>
      </LogoWrapper>
    </section>

    <section className={WrapperMainCSS}>
      <WrapFooterNav>
        <LinkGroup />
      </WrapFooterNav>
    </section>

    <WrapperFoot>
      <div>
        <PartnerGroup />
      </div>
    </WrapperFoot>
  </>
);

export default Footer;
