import React from 'react';
// import { useRouteMatch } from 'react-router';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';
import themeVariables from '../Theme/themeVariables';

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: LoadingPage
  }
);

export const LoadableWindowedBlogCategoryPage = loadable(
  () => import('../Theme/WindowedBlogCategoryPage'),
  {
    fallback: LoadingPage
  }
);

export const LoadableLandingBlogCategoryPage = loadable(
  () => import('../Theme/LandingBlogCategoryPage'),
  {
    fallback: LoadingPage
  }
);

const LoadableCaratCalculator = loadable(
  () => import('../CaratCalculator/CaratCalculator'),
  {
    fallback: LoadingPage
  }
);

const CategoryPage = props => {
  
  if (props?.category?.id === themeVariables.blogRootId) {
    return <LoadableLandingBlogCategoryPage {...props} />;
  }

  if (props?.category?.id === themeVariables.calculator.categoryId) {
    return <LoadableCaratCalculator />;
  }

  if (props?.category?.parent) {
    if (props?.category?.parent?.id === themeVariables.blogRootId || props?.category?.products?.result?.length === 0) {
      return <LoadableWindowedBlogCategoryPage {...props} />;
    }
  }

  return <LoadableStandardCategoryPage {...props} />;
};

export default CategoryPage;
