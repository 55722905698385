import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';
import { storeList } from './storeList';

const NewsletterContainer = styled('div')`
    form {
        padding: 3rem 1rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${theme.colors.greyLight};

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            max-width: 20rem;

            li {
                margin-bottom: 1.25rem;
                display: flex;
                flex-direction: column;

                label {
                    margin-bottom: 0.125rem;
                }

                input {
                    padding: 0.5rem;
                    border: 1px solid ${theme.colors.darkgrey};
                    background: white;

                    &[type='submit'] {
                        cursor: pointer;
                        padding: 1rem 2rem;
                        border: none;
                        background: ${theme.colors.primary};
                        color: ${theme.colors.white};
                        text-transform: uppercase;
                        font-size: 1.125rem;

                        &:hover {
                            background: ${theme.colors.primaryHover};
                        }
                    }
                }

                select {
                    padding: 0.5rem;
                    border: 1px solid ${theme.colors.darkgrey};
                    background: white;
                }

                p {
                    font-size: 0.75rem;
                    font-style: italic;
                    margin-top: 0.5rem;
                    line-height: 1.3;
                }
            }
        }
    }
`;

const GetanewsletterSignup = ({ paddingAbove, paddingBelow }) => {
  paddingAbove = paddingAbove?.value;
  paddingBelow = paddingBelow?.value;

  const cleanPhoneInput = e => {
    let str = e.target.value;
    let keyCode = e.keyCode;
    if (keyCode < 37 || keyCode > 40) {

      if (str.length <= 3) {
        str = str.replace(/\D/g, '');
      }
      if (str.length === 3 && keyCode === 8) {
        str = str.substring(0, 2);
      } else if (str.length === 3) {
        str += '-';
      } else if (str.length > 4) {
        str = str.substring(0, 3) + '-' + str.substring(4).replace(/\D/g, '');

        if (str.length > 11) {
          str = str.substring(0, 11);
        }
      }
      e.target.value = str;
    }
  };

  const parseFinalPhoneInput = e => {
    let str = e.target.value;
    str = str.replace(/\D/g, '');
    if (str.length > 3) {
      str = str.substring(0, 3) + '-' + str.substring(3);
    }
    e.target.value = str;
  };

  const cleanPidInput = e => {
    let str = e.target.value;
    let keyCode = e.keyCode;
    let shouldUpdate = false;

    if (keyCode < 37 || keyCode > 40) {
      console.log('cleanPid');

      if (str.length <= 8 && str.match(/\D/g)) {
        str = str.replace(/\D/g, '');
        shouldUpdate = true;
      }
      if (str.length === 8 && keyCode === 8) {
        str = str.substring(0, 7);
        shouldUpdate = true;
      } else if (str.length === 8) {
        str += '-';
        shouldUpdate = true;

      } else if (str.length > 8) {
        str = str.replace(/\D/g, '');
        str = str.substring(0, 8) + '-' + str.substring(8);

        if (str.length > 13) {
          str = str.substring(0, 13);
        }
        shouldUpdate = true;
      }

      if (shouldUpdate) {
        e.target.value = str;
      }
    }
  };

  const parseFinalPidInput = e => {
    let str = e.target.value;
    str = str.replace(/\D/g, '');
    if (str.length > 8) {
      str = str.substring(0, 8) + '-' + str.substring(8, 12);
    }
    e.target.value = str;
  };

  const setStore = e => {
    document.getElementById('butik').value = e.target.value;
  };

  return (
    <NewsletterContainer
      style={{
        paddingTop: paddingAbove ?? '0',
        paddingBottom: paddingBelow ?? '0'
      }}
    >
      <form method="POST" action="https://gansub.com/s/SjoRprne7A2oz/">
        <ul>
          <li>
            <label htmlFor="first_name">Förnamn</label>
            <input type="text" id="first_name" name="first_name" required />
          </li>
          <li>
            <label htmlFor="last_name">Efternamn</label>
            <input type="text" id="last_name" name="last_name" required />
          </li>
          <li>
            <label htmlFor="email">E-post</label>
            <input type="email" id="email" name="email" required />
          </li>
          <li>
            <label htmlFor="telefonnummer">Telefonnnummer (07X-XX...)</label>
            <input
              title="07X-XXXXXXX"
              type="tel"
              id="telefonnummer"
              name="telefonnummer"
              required
              placeholder="Ex. 071-2345678"
              pattern="07[0-9]-[0-9]{7}"
              onKeyUp={cleanPhoneInput}
              onBlur={parseFinalPhoneInput}

            />
          </li>

          <li>
            <label htmlFor="personnummer">Personnummer (12 siffror)</label>
            <input
              title={'ÅÅÅÅMMDD-XXXX'}
              type="text"
              id="personnummer"
              name="personnummer"
              required
              placeholder="Ex. 19980101-1234"
              pattern="[0-9]{8}-[0-9]{4}"
              onKeyUp={cleanPidInput}
              onBlur={parseFinalPidInput}

            />
          </li>

          <li>
            <label>Din Smycka-butik</label>
            <select
              required
              onChange={e => {
                setStore(e);
              }}
            >
              <option value="">-- Välj butik --</option>
              {storeList.map(store => (
                <option key={store} value={store}>
                  {store}
                </option>
              ))}
            </select>
            <p>Har du ingen Smyckabutik nära dig? Välj "Webbshop" ovan!</p>
          </li>

          <li>
            <input type="hidden" id="butik" name="butik" />
          </li>

          <li>
            <input type="hidden" name="gan_repeat_email" />
            <input type="submit" value="Prenumerera" />
          </li>
        </ul>
      </form>
    </NewsletterContainer>
  );
};

export default GetanewsletterSignup;
