import t from '@jetshop/intl';
import { Below } from '@jetshop/ui/Breakpoints';
import React from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { ReactComponent as Menu } from '../../../svg/IconMenu.svg';
import FavouritesMenuButton from '../../Favourites/FavouritesMenuButton';
import StoreFinderButton from '../../Theme/StoreFinderButton';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MainNav from './MainNav';
import MobileMenu from './MobileMenu';
import { Link } from 'react-router-dom';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import Logo from './Logo';
import SearchBar from './SearchBar';
import MaxWidth from '../MaxWidth';
import UspBar from '../../Theme/Shortcodes/UspBar';
import LinkGroup from '../../Theme/Shortcodes/LinkGroup';
import rafThrottle from '../../Theme/Utils/rafThrottle';
import { theme } from '../../Theme';
import SearchButton from './SearchButton';
import { Notifications } from '../Notifications';
import themeVariables from '../../Theme/themeVariables';

const StyledMaxWidth = styled(MaxWidth)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  font-size: 0.75rem;
  font-weight: ${theme.fontWeights.book};
`;

const HeaderUspContainer = css`
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  color: ${theme.colors.white};
  background: ${theme.colors.blueDark};
  font-size: 0.85rem;
  text-transform: uppercase;
  z-index: 100;

  ${theme.below.lg} {
    order: 2;
    background: ${theme.colors.greyLight};
    color: ${theme.colors.primary};
    height: 58px;

    svg {
      stroke: ${theme.colors.primary};
    }
  }

  .uspbar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    span {
      margin: 2px ${theme.space[2]};
      text-align: center;
    }

    ${theme.below.lg} {
      display: flex;
      justify-content: center;
      width: 100%;

      span {
        margin: 0 ${theme.space[1]};
        font-size: 0.6rem;
      }
    }
  }

  a {
    position: relative;
    z-index: 9;

    &:hover {
      text-decoration: underline;
      color: ${theme.colors.white} !important;
    }
  }
`;

const HeaderContainer = css`
  height: 106px;
  position: relative;
  z-index: 104;
  width: 100%;
  background: ${theme.colors.white};

  ${theme.below.lg} {
    position: fixed;
    left: 0;
    top: 0;
    height: 64px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    z-index: 105;
  }
`;

const HeaderContainerInner = styled(MaxWidth)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;

  &[data-scrolled='true'] {
    background: #fff;
  }
`;

const IconContainer = css`
  display: flex;
  align-items: center;
  width: 26px;
  height: 20px;
  margin: 0;

  svg {
    width: 26px;
    height: 20px;
    stroke: #000;
  }
`;

const MenuButton = styled('span')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;
  > svg {
    height: 18px;
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
  label {
    margin-top: 7px;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: ${theme.fontWeights.medium};
  }
`;

const FlexSection = styled('div')`
  display: flex;
`;

const StyledFlexSection = styled(FlexSection)`
  position: relative;
  justify-content: flex-end;

  > svg {
    width: 22px;
    height: 22px;
    margin-right: ${theme.space[4]};

    &:first-of-type {
      width: 25px;
      height: 25px;
    }

    &:last-of-type {
      margin-right: ${theme.space[4]};

      ${theme.below.lg} {
        margin-right: ${theme.space[2]};
      }
    }
  }
`;

const SocialWrapper = styled('div')`
  .social-icon {
    width: 16px;
    height: 16px;
  }
`;

const Container = styled('header')`
  position: relative;

  ${theme.above.md} {
    > * {
      padding: 0rem 2.5rem;
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: #000;
    }
  }
  ${theme.below.lg} {
    display: flex;
    flex-direction: column;
    margin-top: 64px;

    .mobile--hidden {
      display: none;
    }
  }

  &[data-scrolled='true'] {
    ${theme.above.lg} {
      margin-bottom: 62px;
    }
  }
`;

const MobileSearchButton = styled(SearchButton)`
  left: -42px;
  top: 0.5px;
`;

const SearchBarWrapper = css`
  position: relative;
  width: 380px;
  margin-top: 10px;

  > button {
    left: 0;
    top: -5px;
  }
`;

const MobileSearchBarWrapper = styled('div')`
  padding-top: 10px;
  padding-bottom: 9px;
  border-top: 1px solid #dcdcdc;
  background: #fff;

  && {
    input {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 10px;
      border-bottom: 0;
    }
  }

  ${theme.above.md} {
    display: none;
  }
`;

// const MobileSearchfield = () => {
//   return (
//     <>
//     <StyledSearch
//     onClick={() => {
//       handleKeyPress(drawer);
//     }}
//   />
//   <SearchField
//     autocompleteQuery={AutocompleteQuery}
//     placeholder={t('Sök här')}
//     searchResult={searchResult}
//     resultClick={drawer.hideTarget}
//     clearInputRef={clearInputRef}
//     handleKeyPress={handleKeyPress}
//     drawer={drawer}
//     onCancel={() => false}
//   />
//   </>
//   )
// }

function HeaderFunction(props) {
  const [searchOpen, setSearchOpen] = React.useState(true);
  const [searchIconVisible, setSearchIconVisible] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const [mobileSearchOpen, setMobileSearchOpen] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== 'undefined') {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        if (scrollTop >= 180 && !scrolled) {
          setScrolled(true);
        } else if (scrollTop < 134 && scrolled) {
          setScrolled(false);
        }
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', rafThrottle(handleScroll));
    }

    if (scrolled) {
      setSearchIconVisible(true);
      setSearchOpen(false);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrolled, searchIconVisible]);

  return (
    <>
      <Container data-scrolled={scrolled}>
        <div className={HeaderUspContainer}>
          <StyledMaxWidth>
            <SocialWrapper className="mobile--hidden">
              <LinkGroup onlySocial={true} />
            </SocialWrapper>
            <div className="uspbar-wrapper">
              <UspBar />
            </div>

            <FlexSection className="mobile--hidden">
              <StyledLink to="/kundservice">{t('Customer support')}</StyledLink>
            </FlexSection>
          </StyledMaxWidth>
        </div>
        <div className={HeaderContainer}>
          <HeaderContainerInner data-scrolled={scrolled}>
            <Below breakpoint="lg">
              {matches =>
                matches ? (
                  <>
                    <Link to="/" aria-label="Smycka">
                      <Logo />
                    </Link>

                    <StyledFlexSection>
                      <MobileSearchButton setSearchOpen={setMobileSearchOpen} />
                      <FavouritesMenuButton />
                      <CartButton />

                      <DrawerTrigger preventOverflow={true} id="menu-drawer">
                        {drawer => (
                          <MenuButton
                            onClick={
                              // data-testid="header-cart"
                              drawer.isOpen
                                ? drawer.hideTarget
                                : drawer.showTarget
                            }
                          >
                            <div className={IconContainer}>
                              <Menu />
                            </div>
                          </MenuButton>
                        )}
                      </DrawerTrigger>
                    </StyledFlexSection>
                  </>
                ) : (
                  <>
                    <div className={SearchBarWrapper}>
                      <SearchButton setSearchOpen={() => {}} />
                      <SearchBar searchOpen={true} />
                    </div>

                    <Link to="/" aria-label="Smycka">
                      <Logo />
                    </Link>

                    <StyledFlexSection>
                      <StoreFinderButton />
                      <FavouritesMenuButton />
                      <CartButton />
                    </StyledFlexSection>
                  </>
                )
              }
            </Below>
          </HeaderContainerInner>
          {mobileSearchOpen && (
            <MobileSearchBarWrapper>
              <SearchBar searchOpen={true} autofocus />
            </MobileSearchBarWrapper>
          )}
        </div>
        <Below breakpoint="lg">
          {matches =>
            matches ? (
              <MobileMenu
                data={{
                  categories: hideCategory(props)
                }}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
            ) : (
              <MainNav
                categories={{
                  categories: hideCategory(props)
                }}
                scrolled={scrolled}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
            )
          }
        </Below>
      </Container>
      <CartFlyout />
    </>
  );
}

function hideCategory(props) {
  return props?.result?.data?.categories.filter(
    cat => cat.id !== themeVariables.calculator.categoryId
  );
}

const Header = props => (
  <Query variables={{ levels: 2 }} query={homeCategoriesQuery}>
    {result => (
      <>
        <HeaderFunction result={result} />
        <Notifications />
      </>
    )}
  </Query>
);

export default Header;
