import { useCookies } from 'react-cookie';
import React, { useReducer, useEffect, useContext } from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';

const cookieName = 'selectedFavourites';

const initialState = { favourites: [], ignored: [] };

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case 'ADD_FAVOURITE':
      return {
        ...state,
        favourites: [...state.favourites, payload.articleNumber]
      };

    case 'REMOVE_FAVOURITE':
      return {
        ...state,
        favourites: state.favourites.filter(
          articleNumber => articleNumber !== payload.articleNumber
        )
      };

    case 'CLEAR_FAVOURITES':
      return initialState;

    case 'CHANGE_VARIANT': {
      const currentIndex = state.favourites.indexOf(
        payload.previousArticleNumber
      );
      const favourites = [...state.favourites];
      favourites.splice(currentIndex, 1, payload.articleNumber);

      const ignoredIndex = state.ignored.indexOf(payload.previousArticleNumber);
      const ignored = [...state.ignored];

      if (ignoredIndex > -1) {
        ignored.splice(ignoredIndex, 1, payload.articleNumber);
      }

      return {
        ...state,
        favourites,
        ignored
      };
    }

    case 'IGNORE_FAVOURITE':
      return {
        ...state,
        ignored: [...state.ignored, payload.articleNumber]
      };

    case 'UNIGNORE_FAVOURITE':
      return {
        ...state,
        ignored: state.ignored.filter(
          articleNumber => articleNumber !== payload.articleNumber
        )
      };

    default:
      return state;
  }
}

export const findMatchingArticleNumber = (favourites, product) => {
  if (favourites.indexOf(product.articleNumber) > -1)
    return product.articleNumber;

  return null;
};

const isFavourite = (state, product) =>
  !!findMatchingArticleNumber(state.favourites, product);

const toggleFavourite = (state, dispatch, product, track) => {
  const matchingArticleNumber = findMatchingArticleNumber(
    state.favourites,
    product
  );

  dispatch({
    type: matchingArticleNumber ? 'REMOVE_FAVOURITE' : 'ADD_FAVOURITE',
    payload: {
      articleNumber: matchingArticleNumber || product.articleNumber
    }
  });
};

const changeVariant = (dispatch, prevVariant, newVariant, track) => {
  dispatch({
    type: 'CHANGE_VARIANT',
    payload: {
      previousArticleNumber: prevVariant,
      articleNumber: newVariant
    }
  });
  track({
    name: 'SELECT_FAVOURITE_VARIANT',
    payload: {
      previousArticleNumber: prevVariant,
      articleNumber: newVariant
    }
  });
};

const clearFavourites = dispatch =>
  dispatch({
    type: 'CLEAR_FAVOURITES'
  });

const isIgnored = (state, product) => {
  const matchingArticleNumber = findMatchingArticleNumber(
    state.favourites,
    product
  );

  if (
    matchingArticleNumber &&
    state.ignored.indexOf(matchingArticleNumber) > -1
  )
    return true;
  return false;
};

const toggleIgnored = (state, dispatch, articleNumber) => {
  const isIgnored = state.ignored.indexOf(articleNumber) > -1;
  dispatch({
    type: isIgnored ? 'UNIGNORE_FAVOURITE' : 'IGNORE_FAVOURITE',
    payload: {
      articleNumber
    }
  });
};

const FavouritesContext = React.createContext();

export const FavouritesProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const track = useTracker();
  const favouriteCookie = cookies[cookieName];
  const favourites = favouriteCookie ? favouriteCookie.split(',') : [];

  const [state, dispatch] = useReducer(reducer, { favourites, ignored: [] });

  useEffect(() => {
    if (state.favourites.length > 0) {
      setCookie(cookieName, state.favourites.join(','), {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
        sameSite: true
      });
    } else {
      removeCookie(cookieName, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
        sameSite: true
      });
    }
  }, [state.favourites, setCookie, removeCookie]);

  const value = React.useMemo(
    () => ({
      isFavourite: product => isFavourite(state, product),
      toggleFavourite: product =>
        toggleFavourite(state, dispatch, product, track),
      clearFavourites: () => clearFavourites(dispatch),
      toggleIgnored: articleNumber =>
        toggleIgnored(state, dispatch, articleNumber),
      isIgnored: product => isIgnored(state, product),
      favourites: state.favourites.map(articleNumber => ({
        articleNumber,
        ignored: state.ignored.indexOf(articleNumber) > -1
      })),
      totalFavourites: state.favourites.length,
      changeVariant: (prevVariant, newVariant) =>
        changeVariant(dispatch, prevVariant, newVariant, track),
      findMatchingArticleNumber: product =>
        findMatchingArticleNumber(state.favourites, product)
    }),
    [state, dispatch, track]
  );
  return (
    <FavouritesContext.Provider value={value}>
      {children}
    </FavouritesContext.Provider>
  );
};

const useFavourites = () => useContext(FavouritesContext);

export default useFavourites;
