import Link from '@jetshop/ui/CategoryLink';
import { cx } from 'linaria';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Chevron } from '../../../../svg/IconChevron.svg';
import { theme } from '../../../Theme';

const StyledChevron = styled(Chevron)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    height: 16px;
    width: 10px;
    stroke: ${theme.colors.white};
    z-index: 999;
`;

const ChevronBlue = styled(Chevron)`
    height: 14px;
    width: 8px;
    transform: rotateZ(-90deg);
    stroke: ${theme.colors.primary};
    transition: transform 200ms;

    &.is-open {
        transform: rotate(90deg);
    }
`;

const SubMenu = styled('div')`
    transition: 0.4s left;
    position: absolute;
    height: 100%;
    top: 0;
    left: 100%;
    width: 100%;
    background: ${theme.colors.white};
    box-shadow: 16px 0px 32px 8px rgba(0, 0, 0, 0.2);
    z-index: 16;

    &[data-open='true'] {
        left: 0;
        z-index: 16;
    }

    .sub-cat-ul {
        font-size: 0.875rem;
        padding-bottom: 1rem;

        a {
            border-bottom: none;
            padding: 0.5rem 1rem;
            max-width: fit-content;
            line-height: 1.325;

            &.active,
            &:hover {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 75%;
                    height: 1px;
                    bottom: 6px;
                    background: ${theme.colors.black};
                }
            }
        }

        a.sub-cat-heading {
            font-weight: ${theme.fontWeights.regular};

            &.active,
            &:hover {
                &:after {
                    width: 80%;
                    height: 2px;
                    bottom: 4px;
                }
            }
        }
    }
`;

const NavBarHeader = styled('div')`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    height: 64px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    z-index: 999;
`;

const Scroll = styled('div')`
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    flex: 1 1 auto;
    height: calc(100% - 64px);

    &[data-show-all='true'] {
        height: calc(100% - 162px);
    }
`;

const CategoryTree = styled('ul')`
    display: flex;
    flex-direction: column;
    list-style: none;

    flex: 1 0 auto;

    &:last-child {
        border-bottom: 1px solid ${theme.colors.grey};
    }

    a,
    a:hover {
        text-decoration: none;
    }
`;

const CategoryLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: inherit;
    text-decoration: none;
    padding: 1em 1em 0.9375em; /* Adjust for crappy web-font height */
    /* border-bottom: 1px solid #e7e4e4; */

    &.with-chevron {
        svg {
            display: block;
        }
    }

    position: relative;
`;

const CategoryName = styled('h2')`
    font-size: 16px;
    font-weight: ${theme.fontWeights.medium};
    border-top: 1px solid ${theme.colors.grey};
`;

const RootCategory = styled('li')`
    display: flex;
    flex-direction: column;
`;

const ButtonWrapper = styled('div')`
    display: flex;
    align-items: center;
    height: 98px;
    padding: 1.25rem;
    background: ${theme.colors.greyLight};
    border-top: 1px solid ${theme.colors.grey};
`;

const CloseButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 1 100%;
    height: 56px;
    background: ${theme.colors.primary};
    color: ${theme.colors.white} !important;
    font-size: 0.85rem;
    text-transform: uppercase;
`;

const MobileSubCategories = ({
                               category,
                               subcategories,
                               closeMenu,
                               index,
                               activeSubMenu,
                               setActiveSubMenu,
                               setIsSubOpen
                             }) => {
  const handleClick = () => {
    setActiveSubMenu(false);
    setTimeout(() => {
      setIsSubOpen(false);
      closeMenu();
    }, 400);
  };

  function ActiveChevron({ isActive, isOpen }) {
    return isActive ? null : (
      <ChevronBlue className={cx(ChevronBlue, isOpen ? 'is-open' : null)} />
    );
  }

  return (
    <SubMenu data-open={index === activeSubMenu}>
      <NavBarHeader
        onClick={() => {
          setTimeout(() => {
            setIsSubOpen(false);
          }, 300);
          setActiveSubMenu(false);
        }}
      >
        <StyledChevron />
        {category.name}
      </NavBarHeader>
      <Scroll data-show-all={category.id !== 162}>
        <CategoryTree>
          <Accordion single>
            {({ openIndexes, handleClick, AccordionContent }) =>
              subcategories.map((cat, index) => {
                const open = openIndexes.includes(index);

                return (
                  <RootCategory key={cat.id}>
                    <CategoryName>
                      <CategoryLink
                        category={cat}
                        onClick={e => {
                          if (cat.subcategories.length > 0) {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClick(index);
                          } else {
                            closeMenu();
                            setIsSubOpen(false);
                          }
                        }}
                      >
                        {cat.name}
                        {cat.subcategories.length > 0 && (
                          <ActiveChevron isOpen={open} />
                        )}
                      </CategoryLink>
                    </CategoryName>
                    {cat.subcategories.length > 0 && (
                      <AccordionContent isOpen={open}>
                        <ul className="sub-cat-ul">
                          <li>
                            <CategoryLink
                              category={cat}
                              exact
                              onClick={_e => {
                                handleClick(index);
                                setIsSubOpen(false);
                                closeMenu();
                              }}
                              className="sub-cat-heading"
                            >
                              {t('View all in {category}', {
                                category: cat.name.toLowerCase()
                              })}
                            </CategoryLink>
                          </li>
                          {cat.subcategories.map(subcat => {
                            return (
                              <li key={subcat.id}>
                                <CategoryLink
                                  category={subcat}
                                  onClick={e => {
                                    setIsSubOpen(false);
                                    handleClick(index);
                                    closeMenu();
                                  }}
                                >
                                  {subcat.name}
                                </CategoryLink>
                              </li>
                            );
                          })}
                        </ul>
                      </AccordionContent>
                    )}
                  </RootCategory>
                );
              })
            }
          </Accordion>
        </CategoryTree>
      </Scroll>
      {category.id !== 162 && (
        <ButtonWrapper>
          <CloseButton
            category={category}
            onClick={() => {
              handleClick();
              setIsSubOpen(false);
            }}
          >
            {t('Allt inom')} {category.name}
          </CloseButton>
        </ButtonWrapper>
      )}
    </SubMenu>
  );
};

export default MobileSubCategories;
